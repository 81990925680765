class CardBannerAvailability {
  constructor(sdk) {
    this.sdk = sdk;
  }

  isAvailable() {
      throw new Error('isAvailable must be implemented');
  }
}

class CupidCardBannerAvailability extends CardBannerAvailability {
  productsWithoutCardbanner = [
    "pc",
    "elc",
    "sl"
  ];

  isAvailable() {
    if (this.productsWithoutCardbanner.includes(this.sdk.targetProduct)) return false;

    return this.#checkCardPlaceholder() && this.#checkCustomerID(config?.customerID);
  }

  #checkCardPlaceholder() {
    return !!document.querySelector("[data-martec-placeholder]")
  }

  #checkCustomerID(customerID) {
    const mod100 = customerID % 100;
    return mod100 <= 95 && (mod100 % 16) >= 8;
  }
}

module.exports = {
  CupidCardBannerAvailability
}