const { PRODUCT_SITE_IDS } = require("../consts");
const CardBannerStrategy= require("./CardBannerStrategy");

class CardBannerDefaultStrategy extends CardBannerStrategy {

  isUserEligible() {
    return true;
  }

  async getFreeMember() {
    await this.service.stock.getFreeMembersFromSearch(this.service.targetProduct);

    const freeMemberIds = this.service.stock.freeMembers.map(({ id }) => id);

    return await this.getFreeMemberFromList(freeMemberIds)
  }

  checkTrialAvailability() {
    return window.config?.hasPaid === 'true';
  }

  generateCardLink({ freeMember }) {
    const langPrefix = window.location.pathname.split('/')[1] || 'en'
    const meta = encodeURIComponent(JSON.stringify({ destination: 'profile-page', 'user-id': freeMember.userInfo.id }));
    const landingLink = `/${langPrefix}/registration/sdgProfileCardRedirect?sdgSiteID=${PRODUCT_SITE_IDS[freeMember.userInfo.realm]}&userID=${freeMember.userInfo.id}&meta=${meta}`;
    return landingLink
  }
}

module.exports = CardBannerDefaultStrategy;