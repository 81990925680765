const params = (linkParams) => ([
  () => linkParams,
  params => optimizeParams(params)
].reduce((acc, fn) => ({ ...acc, ...fn(acc) }), {}))

const optimizeParams = (params) => {
  const experiment = window.TerraOptimize?.experiment

  if (!experiment) {
    return {}
  }

  const subafid = (params.subafid ?? '') + ` optimize-experiment=${experiment.id},optimize-variant=${experiment.variant}`

  return {
    subafid
  }
}

module.exports = { params }
