module.exports = class CrossInvitesManager {
  constructor({ maxInvites, inviteWindow }) {
    this.maxInvites = maxInvites;
    this.inviteWindow = inviteWindow;
    this.inviteInterval = inviteWindow / maxInvites;
    this.inviteTimestamps = [];
  }

  canShowInvite() {
    if (window.location.pathname.includes('mail/showemail')) {
      return false;
    }

    const now = Date.now();
    this.inviteTimestamps = this.inviteTimestamps.filter(
      (timestamp) => now - timestamp < this.inviteWindow
    );

    if (this.inviteTimestamps.length >= this.maxInvites) {
      return false;
    }

    const lastInviteTime = this.inviteTimestamps[this.inviteTimestamps.length - 1] || 0;
    return (now - lastInviteTime) >= this.inviteInterval;
  }

  recordInvite() {
    this.inviteTimestamps.push(Date.now());
  }
}
