import CardBannerService from './client-card-banner/CardBannerService'
import CrossInvitesService from './cross-invites/CrossInvitesService'

import Xdls from '/src/sdk/xdls'
import { Family, OriginProduct, Product } from '/src/sdk/consts'

import CrossInvitesServiceFactory from './cross-invites/CrossInvitesServiceFactory'

const utils = require('./utils')
const API = require('./api')
const CrossTokenManager = require('./modules/CrossTokenManager')
const Localization = require('./utils/localization')
const {
  DefaultCoregistrationStrategy,
  PopupCoregistrationStrategy
} = require('./coregistration')
const CardBannerServiceFactory = require('./client-card-banner/CardBannerServiceFactory')
const ActivationPopupServiceFactory = require('./activation-popup/ActivationPopupServiceFactory')
const ActivationPopupService = require('./activation-popup/ActivationPopupService')
const {
  FAMILIES,
  XEROX_PRODUCT_TO_PRODUCT_MAP,
  PRODUCT_TO_XEROX_PRODUCT_MAP,
  PRODUCTS
} = require('./consts')

type LibOptions = {
  targetProduct: OriginProduct
  xeroxHost?: string
  xdlsHost?: string
  apiHosts?: Record<OriginProduct, string>
  fallbackToXdls?: boolean
  ssoTokens?: OriginTokens
}

export type OriginTokens = Record<OriginProduct, { token: string, isLoggedIn: boolean, originProduct?: string }>
export type XeroxTokens = Record<Product, { token: string, isLoggedIn: boolean, originProduct?: string }>

export default class SDK {
  readonly targetProduct: Product
  private readonly targetOriginProduct: string
  private readonly xeroxHost: string
  private readonly xdlsHost: string
  private readonly apiHosts: Record<Product, string>
  private readonly ssoEnabled: boolean
  private readonly fallbackToXdls: boolean
  private readonly cupidToCupidEnabled: boolean
  private readonly xdls: Xdls
  private readonly api: typeof API
  private readonly localization: typeof Localization
  private readonly crossInviteService: CrossInvitesService | null
  private readonly cardBannerService: CardBannerService | null
  private readonly coregistrationStrategy: typeof PopupCoregistrationStrategy
  private readonly ssoTokens: OriginTokens | undefined | null
  private readonly crossTokenManager: typeof CrossTokenManager
  private readonly activationPopupService: typeof ActivationPopupService | null

  constructor ({ targetProduct, xeroxHost, xdlsHost, apiHosts, fallbackToXdls, ssoTokens }: LibOptions) {
    let lsParams: any = {}

    try {
      lsParams = JSON.parse(localStorage.getItem('xerox') || '{}')
    } catch (e) {

    }

    this.targetProduct = this.mapToXeroxProduct(targetProduct)
    this.targetOriginProduct = targetProduct.toLowerCase()
    this.xeroxHost = utils.hostToEndpoint(xeroxHost || lsParams.xeroxHost) || 'https://xerox.clickocean.io/'
    this.xdlsHost = utils.hostToEndpoint(xdlsHost || lsParams.xdlsHost) || 'https://storage.communicationservicesplatform.com/'

    const apiHostsEntries = Object.entries(apiHosts || lsParams.apiHosts || {}) as Array<[OriginProduct, string]>
    this.apiHosts = apiHostsEntries.reduce((acc, [k, v]) =>
      ({ ...acc, [this.mapToXeroxProduct(k)]: utils.hostToEndpoint(v) }), {} as any)

    this.ssoTokens = ssoTokens
    const fragmentParams = new URLSearchParams(window.location.hash.slice(1))
    this.ssoEnabled = lsParams.ssoEnabled ?? (fragmentParams.get('sso_redirect') === 'true' || fragmentParams.has('sso_tokens') || !!this.ssoTokens)
    this.fallbackToXdls = lsParams.fallbackToXdls ?? fallbackToXdls ?? true

    this.cupidToCupidEnabled = true

    const family = this.getProductFamily(this.targetProduct)

    this.xdls = new Xdls({ host: this.xdlsHost })
    this.api = new API({ xeroxHost: this.xeroxHost })
    this.localization = new Localization({
      language: utils.getPageLanguage({ family }),
      xeroxHost: this.xeroxHost,
    })
    this.crossTokenManager = new CrossTokenManager({ api: this.api })

    const crossInvitesServiceFactory = new CrossInvitesServiceFactory(this)
    this.crossInviteService = crossInvitesServiceFactory.create(family)

    const cardBannerServiceFactory = new CardBannerServiceFactory(this)
    this.cardBannerService = cardBannerServiceFactory.create(family)

    const activationPopupServiceFactory = new ActivationPopupServiceFactory(this)
    this.activationPopupService = activationPopupServiceFactory.create(family)

    if (family === FAMILIES.CM) {
      this.coregistrationStrategy = new PopupCoregistrationStrategy(this)
    } else {
      this.coregistrationStrategy = new DefaultCoregistrationStrategy(this)
    }
  }

  async coregistrateByToken (id: string, product: string, token: string, affiliate: boolean | number, authRequired: boolean) {
    return await this.api.forceCoregistration({
      id,
      token,
      product,
      targetProduct: this.targetProduct,
      affiliate,
      authRequired
    })
  }

  async coregistrate (options = {}) {
    return this.coregistrationStrategy.coregistrate(this, options)
  }

  async authorize (token = '') {
    await this.xdls.patch({
      path: ['xerox', 'tokens'],
      value: { [this.mapToCpProduct(this.targetProduct)]: { token, isLoggedIn: true } }
    })

    this.crossInviteService && this.crossInviteService.start({ token })
    this.cardBannerService && this.cardBannerService.start({ token })
    this.activationPopupService && this.activationPopupService.start({ token })
  }

  async unauthorize () {
    const tokens = await this.xdls.getItem({ path: ['xerox', 'tokens'] }) ?? {}
    const token = tokens[this.mapToCpProduct(this.targetProduct)]?.token ?? ''

    await this.xdls.patch({
      path: ['xerox', 'tokens'],
      value: { [this.mapToCpProduct(this.targetProduct)]: { token, isLoggedIn: false } }
    })
  }

  async coregistrateAndRedirect (
    { affiliate, authRequired, id, product, token }: {
      affiliate: boolean | number,
      authRequired: boolean,
      id: string,
      product: string,
      token: string
    },
    callback: (() => void) | undefined | null = null
  ) {
    const uri = await this.coregistrateByToken(id, PRODUCT_TO_XEROX_PRODUCT_MAP.get(product) || product, token, affiliate, authRequired)
    if (!uri) return

    let targetToken = new URLSearchParams(new URL(uri).hash.substring(1)).get('token')

    if (!targetToken) {
      targetToken = new URL(uri).searchParams.get('token')
    }

    if (targetToken) {
      await callback?.()
    }

    if (targetToken || !authRequired) {
      await this.saveTokenFromUriToXdls(uri)

      const xdlsTokensToSSO = await this.getXdlsTokensToSSO()

      utils.redirectToUri(uri, {
        product: this.mapToCpProduct(this.targetProduct),
        token: targetToken,
        ssoEnabled: this.ssoEnabled,
        xdlsTokensToSSO
      })
    }
  }

  async saveTokenFromUriToXdls (uri: string) {
    const family = this.getProductFamily(this.targetProduct)
    if (family === FAMILIES.UD || family === FAMILIES.AF || family === FAMILIES.CM) {
      let token
      if (family === FAMILIES.UD || family === FAMILIES.AF) {
        token = new URLSearchParams(new URL(uri).hash.substring(1)).get('token')

        if (!token) {
          token = new URL(uri).searchParams.get('token')
        }

      } else if (family === FAMILIES.CM) {
        token = new URL(uri).searchParams.get('emailtoken')
      }

      await this.xdls.patch({
        path: ['xerox', 'tokens'],
        value: { [this.mapToCpProduct(this.targetProduct)]: { token: token || '', isLoggedIn: !!token } }
      })
    }
  }

  async getXdlsTokensToSSO (): Promise<OriginTokens> {
    if (!this.ssoEnabled || !this.fallbackToXdls) return {} as OriginTokens

    const tokensFromXdls = await this.getTokensFromXdls()
    if (!tokensFromXdls) return {} as OriginTokens
    const { tokens: xdlsTokens } = tokensFromXdls
    if (!xdlsTokens) return {} as OriginTokens

    const tokensFromSSO: { tokens: XeroxTokens } = this.getTokensFromSSO() || { tokens: {} as XeroxTokens }
    const { tokens: ssoTokens } = tokensFromSSO

    const products = Object.keys(xdlsTokens) as Product[]

    return products.reduce<OriginTokens>((acc, product) => {
      if (!ssoTokens[product]) {
        const { token, isLoggedIn } = xdlsTokens[product]
        acc[this.mapToCpProduct(product)] = { token, isLoggedIn }
      }
      return acc
    }, {} as OriginTokens)
  }

  getTokensFromSSO (): { tokens: XeroxTokens } | undefined {
    try {
      if (!this.ssoEnabled) return

      let ssoTokens: OriginTokens = this.ssoTokens || ({} as OriginTokens)

      const ssoTokensParam = new URLSearchParams(location.hash.slice(1)).get('sso_tokens')

      if (ssoTokensParam) {
        ssoTokens = JSON.parse(decodeURIComponent(ssoTokensParam))
      }

      const products = Object.keys(ssoTokens || {}) as OriginProduct[]
      const tokens: XeroxTokens = products.reduce<XeroxTokens>((acc, product) => {
        const item = ssoTokens[product]

        acc[this.mapToXeroxProduct(product)] = {
          token: item.token,
          isLoggedIn: item.isLoggedIn,
          originProduct: product,
        }

        return acc
      }, {} as XeroxTokens)

      if (!tokens || tokens.hasOwnProperty(this.targetProduct)) {
        return
      }

      return { tokens }

    } catch (e) {}
  }

  async getTokensFromXdls (): Promise<{ appdataToken: string, tokens: XeroxTokens } | undefined> {
    const appdataToken: string = await this.xdls.getItem({ path: ['__appdata', 'token'] })
    const xdlsTokens: OriginTokens = await this.xdls.getItem({ path: ['xerox', 'tokens'] })

    const products = Object.keys(xdlsTokens || {}) as OriginProduct[]
    const tokens = products.reduce<XeroxTokens>((acc, product) => {
      const item = xdlsTokens[product]

      acc[this.mapToXeroxProduct(product)] = {
        token: item.token,
        isLoggedIn: item.isLoggedIn,
        originProduct: product,
      }

      return acc
    }, {} as XeroxTokens)

    if (!tokens || tokens.hasOwnProperty(this.targetProduct)) {
      return
    }

    return { appdataToken, tokens }
  }

  mapToXeroxProduct (product: OriginProduct): Product {
    return PRODUCT_TO_XEROX_PRODUCT_MAP.get(product.toLowerCase()) || product
  }

  mapToCpProduct (product: Product): OriginProduct {
    return XEROX_PRODUCT_TO_PRODUCT_MAP.get(product) || product
  }

  getProductFamily (product: Product): Family | undefined {
    return (Object.keys(FAMILIES) as Array<Family>).find((family) => PRODUCTS[family].includes(product))
  }
}
