export type Family = 'CP' | 'AF' | 'UD' | 'CM'

export const FAMILIES: Record<Family, Family> = {
  CP: 'CP',
  AF: 'AF',
  UD: 'UD',
  CM: 'CM',
}

export type Product =
  'africa'
  | 'slavic1'
  | 'japan'
  | 'guys'
  | 'pink'
  | 'arabia'
  | 'china'
  | 'christian'
  | 'euro'
  | 'eurodate'
  | 'flirt'
  | 'latin'
  | 'mature'
  | 'travel'
  | 'pina'
  | 'woman'
  | 'astro'
  | 'asian'
  | 'asiandate'
  | 'slavic'
  | 'anastasiadate'
  | 'russianbrides'
  | 'locals'
  | 'aic'
  | 'asdc'
  | 'bbwc'
  | 'bc'
  | 'brc'
  | 'cmc'
  | 'crc'
  | 'clc'
  | 'chrc'
  | 'cnc'
  | 'dmc'
  | 'fc'
  | 'gc'
  | 'hkc'
  | 'indc'
  | 'idc'
  | 'cupid-international'
  | 'inrc'
  | 'jc'
  | 'kj'
  | 'kc'
  | 'lac'
  | 'mlc'
  | 'mxc'
  | 'mic'
  | 'msa'
  | 'pc'
  | 'slc'
  | 'sac'
  | 'thac'
  | 'ukrc'
  | 'vietc'
  | 'eurc'
  | 'elc'
  | 'sl'

export const PRODUCTS: Record<string, Product[]> = {
  [FAMILIES.CP]: [
    'africa',
    'slavic1',
    'arabia',
    'china',
    'christian',
    'euro',
    'eurodate',
    'flirt',
    'latin',
    'mature',
    'travel',
    'pina',
    'japan',
    'guys',
    'pink',
    'woman',
    'astro',
  ],
  [FAMILIES.AF]: [
    'asian',
    'asiandate',
    'slavic',
    'anastasiadate',
    'russianbrides',
  ],
  [FAMILIES.UD]: [
    'locals',
  ],
  [FAMILIES.CM]: [
    'aic',
    'asdc',
    'bbwc',
    'bc',
    'brc',
    'cmc',
    'crc',
    'clc',
    'chrc',
    'cnc',
    'dmc',
    'fc',
    'gc',
    'hkc',
    'indc',
    'idc',
    'cupid-international',
    'inrc',
    'jc',
    'kj',
    'kc',
    'lac',
    'mlc',
    'mxc',
    'mic',
    'msa',
    'pc',
    'slc',
    'sac',
    'thac',
    'ukrc',
    'vietc',
    'eurc',
    'elc',
    'sl'
  ]
}

export type OriginProduct =
  'dating'
  | 'pinadate'
  | 'anastasiadate'
  | 'asiandate'
  | 'mature'
  | 'locals'
  | 'arabia'
  | 'africa'
  | 'ourlove'
  | 'astrolove'
  | 'euro'
  | 'china'
  | 'christian'
  | 'latin'
  | 'travel'
  | 'zen'
  | 'amal'
  | 'hotti'
  | 'afr'
  | 'ase'
  | 'bbw'
  | 'blc'
  | 'brz'
  | 'cam'
  | 'cbc'
  | 'cll'
  | 'chc'
  | 'coc'
  | 'doc'
  | 'fic'
  | 'hkc'
  | 'idc'
  | 'lac'
  | 'mxc'
  | 'slc'
  | 'sac'
  | 'elc'
  | 'gay'
  | 'inc'
  | 'int'
  | 'irc'
  | 'jac'
  | 'kyc'
  | 'koc'
  | 'mls'
  | 'mll'
  | 'mus'
  | 'pic'
  | 'sll'
  | 'tll'
  | 'ukd'
  | 'vnc'
  | 'rse'

export const PRODUCT_TO_XEROX_PRODUCT_MAP: Map<OriginProduct, Product> = new Map([
  ['dating', 'eurodate'],
  ['pinadate', 'pina'],
  ['zen', 'china'],
  ['amal', 'arabia'],
  ['hotti', 'slavic1'],
  ['ourlove', 'woman'],
  ['astrolove', 'astro'],
  ['afr', 'aic'],
  ['ase', 'asdc'],
  ['bbw', 'bbwc'],
  ['blc', 'bc'],
  ['brz', 'brc'],
  ['cam', 'cmc'],
  ['cbc', 'crc'],
  ['cll', 'clc'],
  ['chc', 'chrc'],
  ['coc', 'cnc'],
  ['doc', 'dmc'],
  ['fic', 'fc'],
  ['gay', 'gc'],
  ['hkc', 'hkc'],
  ['inc', 'indc'],
  ['idc', 'idc'],
  ['int', 'cupid-international'],
  ['irc', 'inrc'],
  ['jac', 'jc'],
  ['kyc', 'kj'],
  ['koc', 'kc'],
  ['lac', 'lac'],
  ['mls', 'mlc'],
  ['mxc', 'mxc'],
  ['mll', 'mic'],
  ['mus', 'msa'],
  ['pic', 'pc'],
  ['sll', 'slc'],
  ['sac', 'sac'],
  ['tll', 'thac'],
  ['ukd', 'ukrc'],
  ['vnc', 'vietc'],
  ['rse', 'eurc'],
  ['elc', 'elc'],
  ['slc', 'sl']
])

export const XEROX_PRODUCT_TO_PRODUCT_MAP: Map<Product, OriginProduct> = new Map(Array.from(PRODUCT_TO_XEROX_PRODUCT_MAP).map(([k, v]) => ([v, k])))

export const API_ENDPOINTS = {
  'africa': 'https://api.africandate.com/',
  'hotti': 'https://api.hotti.com/',
  'slavic1': 'https://api.hotti.com/',
  'amal': 'https://api.arabiandate.com/',
  'arabia': 'https://api.arabiandate.com/',
  'zen': 'https://api.chinalove.com/',
  'china': 'https://api.chinalove.com/',
  'christian': 'https://api.yourchristiandate.com/',
  'euro': 'https://api.eurodate.com/',
  'dating': 'https://api.dating.com/',
  'eurodate': 'https://api.dating.com/',
  'flirt': 'https://api.flirtwith.com/',
  'latin': 'https://api.amolatina.com/',
  'locals': 'https://api.udates.io/',
  'mature': 'https://api.datemyage.com/',
  'travel': 'https://api.yourtravelmates.com/',
  'pinadate': 'https://api.pinadate.com/',
  'pina': 'https://api.pinadate.com/',
  'ourlove': 'https://api.ourlove.com/',
  'woman': 'https://api.ourlove.com/',
  'astro': 'https://api.astrolove.com/',
  'astrolove': 'https://api.astrolove.com/',
  'asian': 'https://cp.asiandate.com/',
  'asiandate': 'https://api.asiandate.com/v2/',
  'slavic': 'https://cp.anastasiadate.com/',
  'anastasiadate': 'https://api.anastasiadate.com/v2/',
  'russianbrides': 'https://api.russianbrides.com/v2/',
  'aic': 'https://www.afrointroductions.com',
  'asdc': 'https://www.asiandating.com',
  'bbwc': 'https://www.bbwcupid.com',
  'bc': 'https://www.blackcupid.com',
  'brc': 'https://www.brazilcupid.com',
  'cmc': 'https://www.cambodiancupid.com',
  'crc': 'https://www.caribbeancupid.com',
  'clc': 'https://www.chinalovecupid.com',
  'chrc': 'https://www.christiancupid.com',
  'cnc': 'https://www.colombiancupid.com',
  'dmc': 'https://www.dominicancupid.com',
  'fc': 'https://www.filipinocupid.com',
  'gc': 'https://www.gaycupid.com',
  'hkc': 'https://www.hongkongcupid.com',
  'indc': 'https://www.indiancupid.com',
  'idc': 'https://www.indonesiancupid.com',
  'cupid-international': 'https://www.internationalcupid.com',
  'inrc': 'https://www.interracialcupid.com',
  'jc': 'https://www.japancupid.com',
  'kj': 'https://www.kenyancupid.com',
  'kc': 'https://www.koreancupid.com',
  'lac': 'https://www.latinamericancupid.com',
  'mlc': 'https://www.malaysiancupid.com',
  'mxc': 'https://www.mexicancupid.com',
  'mic': 'https://www.militarycupid.com',
  'msa': 'https://www.muslima.com',
  'pc': 'https://www.pinkcupid.com',
  'slc': 'https://www.singaporelovelinks.com',
  'sac': 'https://www.southafricancupid.com',
  'thac': 'https://www.thaicupid.com',
  'ukrc': 'https://www.ukrainedate.com',
  'vietc': 'https://www.vietnamcupid.com',
  'eurc': 'https://www.eurocupid.com',
  'elc': 'https://www.elitecupid.com',
  'sl': 'https://www.silverlovecupid.com'
}

export const PRODUCT_NAMES = {
  'afr': 'AfroIntroductions',
  'ase': 'AsianDating',
  'bbw': 'BBWCupid',
  'blc': 'BlackCupid',
  'brz': 'BrazilCupid',
  'cam': 'CambodianCupid',
  'cbc': 'CaribbeanCupid',
  'cll': 'ChinaLoveCupid',
  'chc': 'ChristianCupid',
  'coc': 'ColombianCupid',
  'doc': 'DominicanCupid',
  'fic': 'FilipinoCupid',
  'gay': 'GayCupid',
  'hkc': 'HongKongCupid',
  'inc': 'IndianCupid',
  'idc': 'IndonesianCupid',
  'int': 'InternationalCupid',
  'irc': 'InterracialCupid',
  'jac': 'JapanCupid',
  'kyc': 'KenyanCupid',
  'koc': 'KoreanCupid',
  'lac': 'LatinAmericanCupid',
  'mls': 'MalaysianCupid',
  'mxc': 'MexicanCupid',
  'mll': 'MilitaryCupid',
  'mus': 'Muslima',
  'pic': 'PinkCupid',
  'sll': 'SingaporeLovelinks',
  'sac': 'SouthAfricanCupid',
  'tll': 'ThaiCupid',
  'ukd': 'UkraineDate',
  'vnc': 'VietnamCupid',
  'rse': 'EuroCupid',
  'elc': 'EliteCupid',
  'slc': 'SilverLoveCupid',
  'africa': 'AfricanDate',
  'dating': 'Dating.com',
  'hotti': 'Hotti',
  'slavic1': 'Hotti',
  'arabia': 'ArabianDate',
  'amal': 'AmalDate',
  'china': 'ChinaLove',
  'zen': 'ZenDate',
  'christian': 'YourChristianDate',
  'euro': 'EuroDate',
  'eurodate': 'EuroDate',
  'flirt': 'FlirtWith',
  'latin': 'AmoLatina',
  'mature': 'DateMyAge.com',
  'travel': 'YourTravelMates',
  'pina': 'PinaDate',
  'pinadate': 'PinaDate',
  'ourlove': 'OurLove',
  'woman': 'OurLove',
  'astro': 'AstroLove',
  'astrolove': 'AstroLove',
  'asian': 'AsianDate',
  'asiandate': 'AsianDate',
  'anastasiadate': 'AnastasiaDate',
  'russianbrides': 'RussianBrides',
}

export const PRODUCT_ENDPOINTS = {
  'dating': 'https://www.dating.com/',
  'eurodate': 'https://www.dating.com/',
  'africa': 'https://www.africandate.com/',
  'slavic1': 'https://www.hotti.com/',
  'arabia': 'https://www.amaldate.com/',
  'china': 'https://www.zendate.com/',
  'christian': 'https://www.yourchristiandate.com/',
  'euro':'https://www.eurodate.com/',
  'guys': 'https://www.guysonly.com/',
  'pink': 'https://www.girlsonlydating.com/',
  'latin': 'https://www.amolatina.com/',
  'locals': 'https://www.getonce.com/',
  'mature': 'https://www.datemyage.com/',
  'travel': 'https://www.yourtravelmates.com/',
  'japan': 'https://www.kiseki.com/',
  'pina': 'https://www.pinadate.com/',
  'woman': 'https://www.ourlove.com/',
  'astro': 'https://www.astrolove.com/',
  'asiandate': 'https://www.asiandate.com/',
  'asian': 'https://www.asiandate.com/',
  'anastasiadate': 'https://www.anastasiadate.com/',
  'slavic': 'https://www.anastasiadate.com/'
}

export const IMAGES_API_ENDPOINTS = {
  'dating': 'https://api4.dating.com/',
  'eurodate': 'https://api4.dating.com/',
}

export const PRODUCT_SITE_IDS = {
  'dating': 1001,
  'eurodate': 1001,
}

export const PRODUCTS_DISABLED_AS_SOURCE: Product[] = ['woman', 'astro']
