const ActivationPopupService = require('./ActivationPopupService');
const { CupidActivationPopupRenderer } = require("./ActivationPopupRenderer");
const { CupidActivationPopupAvailability } = require("./ActivationPopupAvailability");
const { FAMILIES } = require('../consts')

class ActivationPopupServiceFactory {
  constructor(sdk) {
    this.sdk = sdk
  }

  create(family) {
    switch (family) {
      case FAMILIES.CM:
        return new ActivationPopupService(this.sdk, {
          activationPopupAvailability: new CupidActivationPopupAvailability(this.sdk),
          activationPopupRenderer: new CupidActivationPopupRenderer(this.sdk),
          config: {
            minDays: 3,
            maxDays: 7
          }
        })
      default:
        return null
    }
  }
}

module.exports = ActivationPopupServiceFactory;
