class CrossInvitesAvailability {
  constructor(sdk) {
    this.sdk = sdk;
  }

  isAvailable() {
      throw new Error('isAvailable must be implemented');
  }
}

class CupidCrossInvitesAvailability extends CrossInvitesAvailability {
  productsWithoutCrossInvites = [
    "pc",
    "elc",
    "sl"
  ];

  isAvailable() {
    if (this.productsWithoutCrossInvites.includes(this.sdk.targetProduct)) return false;

    const configMeta = document.getElementById('cm-app-config');
    if (configMeta) {
        const config = JSON.parse(configMeta.getAttribute('content'));
        return this.#parseBoolean(config?.hasPaid) && this.#parseBoolean(config?.notifications?.newMessages);
    }
    return false;
  }

  #parseBoolean (value) {
    if (typeof value === 'boolean') {
      return value;
    }

    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }

    return Boolean(value);
  }
}

module.exports = {
  CupidCrossInvitesAvailability
}
