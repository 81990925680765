const FreeMembersStock = require("./FreeMembersStock");
const CardBannerStrategyFactory = require("./CardBannerStrategyFactory");
const { API_ENDPOINTS } = require("../consts");

const PRODUCT = 'eurodate'

module.exports = class CardBannerService {
  constructor(sdk, { cardBannerAvailability, cardBannerRenderer, pubsub }) {
    this.sdk = sdk;
    this.cardBannerAvailability = cardBannerAvailability;
    this.cardBannerRenderer = cardBannerRenderer;
    this.pubsub = pubsub;

    this.sourceProduct = this.sdk.targetProduct;
    this.targetProduct = PRODUCT;
    this.cupidAccount = null;
  }

  async start({ token }) {
    try {
      if (!this.cardBannerAvailability.isAvailable()) {
        console.log("CardBannerService: unavailable");
        this.#cancel();
        return;
      }

      this.cupidToken = token

      await this.sdk.localization.loadNamespace('client-card-banner')

      this.cupidAccount = await this.#getCupidAccout();

      if (!this.cupidAccount) {
        console.log("CardBannerService: unable to get cupid account details");
        this.#cancel();
        return;
      }

      this.crossToken = await this.sdk.crossTokenManager.getCrossToken({
        sourceProduct: this.sourceProduct,
        sourceToken: this.cupidToken
      });

      this.stock = new FreeMembersStock({
        sdk: this.sdk,
        crossToken: this.crossToken,
        cupidAccount: this.cupidAccount
      })

      const cardBannerStrategy = CardBannerStrategyFactory.create(this);

      const isUserEligible = await cardBannerStrategy.isUserEligible();

      if (!isUserEligible) {
        console.log("CardBannerService: user is not eligible");
        this.#cancel();
        return;
      }

      const freeMember = await cardBannerStrategy.getFreeMember();

      if (!freeMember) {
        console.log("CardBannerService: unable to get free member");
        this.#cancel();
        return;
      }

      const isTrialAvailable = await cardBannerStrategy.checkTrialAvailability();

      const cardLink = cardBannerStrategy.generateCardLink({ freeMember, isTrialAvailable });

      this.cardBannerRenderer.render({
        cardLink,
        freeMember,
        isTrialAvailable,
        onShow: () => {
          this.pubsub.publish({
            label: 'user_card_banner',
            action: 'impression',
            goal: 'improvecoregistrations',
            placement: 'results',
            category: 'banner',
            formData: {
              freeMemberId: freeMember.id,
              sourceProduct: this.sourceProduct,
              targetProduct: this.targetProduct,
              trial: isTrialAvailable,
              coregistrated: !!this.crossToken
            }
          })
        },
        onClick: () => {
          this.pubsub.publish({
            label: 'user_card_banner',
            action: 'click',
            goal: 'improvecoregistrations',
            placement: 'results',
            category: 'banner',
            formData: {
              freeMemberId: freeMember.id,
              sourceProduct: this.sourceProduct,
              targetProduct: this.targetProduct,
              trial: isTrialAvailable,
              coregistrated: !!this.crossToken
            }
          })
        },
      });
    } catch (error) {
      console.error("CardBannerService: Error starting card banner service:", error);
      this.#cancel();
    }
  }

  async #getCupidAccout () {
    try {
      const langPrefix = window.location.pathname.split('/')[1] || 'en'
      return await this.sdk.api.getCupidAccountDetails({
        token: this.cupidToken,
        apiHost: `${API_ENDPOINTS[this.sdk.targetProduct]}/${langPrefix}/`,
      });
    } catch (error) {
      console.error("CardBannerService: Error getting cupid account:", error);
      return null
    }
  }

  #cancel() {
    this.cardBannerRenderer.hidePlaceholder();
  }
}
