const FreeMembersManager = require("./FreeMembersManager");

class CardBannerStrategy {
  constructor(service) {
    this.service = service;
  }

  isUserEligible() {
    throw new Error("isUserEligible method must be implemented");
  }

  async getFreeMemberFromList(freeMemberIds){
    const remainingIds = new Set(freeMemberIds)
    let freeMember = null
    let retries = 5

    while (!freeMember && retries > 0 && remainingIds.size > 0) {
      const freeMemberId =  FreeMembersManager.chooseFreeMemberId(Array.from(remainingIds))

      remainingIds.delete(freeMemberId)
      retries--

      freeMember = await this.service.stock.getFreeMemberProfileById(
          this.service.targetProduct,
          this.service.sourceProduct,
          freeMemberId
      )
    }

    return freeMember
  }

  async getFreeMember() {
    throw new Error("getFreeMember method must be implemented");
  }

  checkTrialAvailability() {
    throw new Error("checkTrialAvailability method must be implemented");
  }

  generateCardLink() {
    throw new Error("generateCardLink method must be implemented");
  }
}

module.exports = CardBannerStrategy;