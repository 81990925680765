const { API_ENDPOINTS, IMAGES_API_ENDPOINTS } = require("../consts");
const { tryConvertProductToSDGNaming } = require("../utils/products");
const preloadImage = require("../utils/preload-image");

const MODEST_PRODUCTS = new Set(['msa'])

module.exports = class FreeMembersStock {
  constructor({ sdk, crossToken, cupidAccount }) {
    this.sdk = sdk;
    this.crossToken = crossToken;
    this.cupidAccount = cupidAccount;

    this.freeMembers = [];
  }

  isEmtpy() {
    return this.freeMembers.length === 0;
  }

  async getFreeMemberProfileById(product, sourceProduct, freeMemberId) {
    try {
      const apiHost = API_ENDPOINTS[product]

      if (!apiHost || !freeMemberId) {
        return null
      }

      const [userInfo, userPreferences] = await Promise.all([
        this.sdk.api.getUserInfo({ apiHost, userId: freeMemberId }),
        this.sdk.api.getUserPreferences({ apiHost, userId: freeMemberId })
      ])
      userInfo.age = new Date().getFullYear() - new Date(userInfo.birthday).getFullYear();

      let userThumbnail = userInfo.thumbnail

      if (MODEST_PRODUCTS.has(sourceProduct)) {
        const photos = await this.sdk.api.getUserPhotos({ apiHost, userId: freeMemberId })

        const modestPhoto = photos.find(photo =>
            photo.tags.includes('modest')
            && !photo.tags.includes('hidden')
            && !photo.tags.includes('unapproved')
        )

        userThumbnail = modestPhoto && modestPhoto.basename
      }

      if (!userThumbnail) {
        return null
      }

      await preloadImage(`${IMAGES_API_ENDPOINTS[product]}/users/${userInfo.id}/photos/${userThumbnail}.250x250.thumb-fd`)

      return {
        id: freeMemberId,
        userInfo,
        userPreferences,
        userThumbnail
      };
    } catch (error) {
      console.error('Error while processing free-member:', error);
      return null
    }
  }

  async getFreeMembersFromContactList(product) {
    try {
      this.freeMembers = await this.sdk.api.getFreeMembersFromContactList({
        product: tryConvertProductToSDGNaming(product),
        memberUUID: this.cupidAccount.memberUUID,
        crossToken: this.crossToken
      });
    } catch (error) {
      if (error.status === 410) {
        throw error;
      } else {
        console.error('FreeMembersStock: Error getting free members from contact list:', error);
      }
    }
    return this.freeMembers;
  }

  async getFreeMembersFromSearch(product) {
    const genderMapping = {
      'Male': 'mal',
      'Female': 'fem',
      'Any': 'both'
    }
    
    try {
      const gender = genderMapping[this.cupidAccount.searchCriteria.gender]
      const preferredGender = genderMapping[this.cupidAccount.gender]
      const [minage, maxage] = this.cupidAccount.searchCriteria.ageRange.split('-').map(Number)

      this.freeMembers = await this.sdk.api.getFreeMembersFromSearch({
        product: tryConvertProductToSDGNaming(product),
        gender,
        preferredGender,
        minage,
        maxage
      });
    } catch (error) {
      console.error('FreeMembersStock: Error getting free members from search:', error);
    }
    return this.freeMembers;
  }
}