const { FAMILIES } = require('../consts')

module.exports = function getPageLanguage({ family } = {}) {
  const lang = document.documentElement.lang

  if (family === FAMILIES.CM) {
    return getCupidLanguageCode(lang)
  }

  return lang.split('-')[0]
}

const getCupidLanguageCode = (lang) => {
  switch (lang) {
    case 'zh-Hans':
      return 'zc'
    case 'zh-Hant':
    case 'zh-TW':
      return 'zt'
    case 'nb-NO':
      return 'no'
    default:
      return lang.split('-')[0]
  }
}