module.exports = function hostToEndpoint (host) {

  if (!host) {
    return null
  }

  if (host.slice(0, 4) !== 'http') {
    host = 'https://' + host
  }

  if (host.slice(-1) !== '/') {
    host += '/'
  }

  return host
}