module.exports = class CrossTokenManager {
    constructor({ api }) {
        this.api = api;
        this._tokenPromise = null;
        this._token = null;
        this._tokenPromiseV2 = null;
        this._tokenV2 = null;
    }

    async getCrossToken({ sourceProduct, sourceToken }) {
        // For DC only
        if (this._token) {
            return this._token;
        }

        if (!this._tokenPromise) {
            this._tokenPromise = this.api.getCrossToken({ sourceProduct, sourceToken })
                .then(token => {
                    this._token = token;
                    return token;
                })
                .catch(error => {
                    if (error.status === 204) {
                        console.log("CrossTokenManager: User is not coregistered");
                    } else {
                        console.error("CrossTokenManager: Error getting cross token:", error);
                    }
                    return null
                })
                .finally(() => {
                    this._tokenPromise = null;
                });
        }

        return this._tokenPromise;
    }

    async getCrossTokenV2({ sourceProduct, sourceToken }) {
        // For any CP product
        if (this._tokenV2) {
            return this._tokenV2;
        }

        if (!this._tokenPromiseV2) {
            this._tokenPromiseV2 = this.api.getCrossTokenV2({ sourceProduct, sourceToken })
                .then(token => {
                    this._tokenV2 = token;
                    return token;
                })
                .catch(error => {
                    if (error.status === 204) {
                        console.log("CrossTokenManager v2: User is not coregistered");
                    } else {
                        console.error("CrossTokenManager v2: Error getting cross token:", error);
                    }
                    return null
                })
                .finally(() => {
                    this._tokenPromiseV2 = null;
                });
        }

        return this._tokenPromiseV2;
    }
}
