const CoregistrationStrategy = require("./CoregistrationStrategy");
const querystring = require('querystring')
const { FAMILIES, API_ENDPOINTS, PRODUCTS_DISABLED_AS_SOURCE } = require("../consts");

class DefaultCoregistrationStrategy extends CoregistrationStrategy {
  async coregistrate(sdk) {
    this.sdk = sdk
    try {
      const params = await this.getCoregistrationParams();

      if (!params) {
        return
      }

      await this.sdk.coregistrateAndRedirect(params);
    } catch (e) {
      console.error(e);
    }
  }

  async getCoregistrationParams() {
    let params = this.extractParamsFromUrl();
    let affiliate = true;

    if (!params || !params.token || !params.product) {
      params = await this.extractParamsFromSSO();
      affiliate = false;
    }

    if (!this.sdk.ssoEnabled || this.sdk.fallbackToXdls) {
      if (!params || !params.token || !params.product) {
        params = await this.extractParamsFromXdls();
        affiliate = false;
      }
    }

    if (!params || !params.token || !params.product) {
      return;
    }

    params.affiliate = affiliate;
    params.authRequired = true;

    const apiHost = this.sdk.apiHosts[params.product] || API_ENDPOINTS[params.product]

    if (!apiHost) {
      throw new Error(`Product ${product} is not supported`)
    }

    const identity = await this.sdk.api.getIdentity({ apiHost, token: params.token })

    params.id = identity.id

    return params;
  }

  extractParamsFromUrl() {
    const query = window.location.hash.substring(1);
    const params = querystring.parse(query);
    const token = params.secret;
    const product = params.product;

    if (PRODUCTS_DISABLED_AS_SOURCE.includes(product)) {
        return null;
    }

    return { token, product };
  }

  extractParamsFromSSO() {
    const result = this.sdk.getTokensFromSSO()
    if (!result) return;

    const { tokens } = result;

    const family = this.sdk.getProductFamily(this.sdk.targetProduct)

    switch (family) {
      case FAMILIES.UD:
        return this.getTokenForUD(null, tokens)
      case FAMILIES.CP:
        return this.getTokenForCP(null, tokens)
      case FAMILIES.AF:
        return this.getTokenForAF(null, tokens)
    }
  }

  async extractParamsFromXdls() {
    const result = await this.sdk.getTokensFromXdls();
    if (!result) return;

    const { appdataToken, tokens } = result;

    if (!appdataToken && Object.keys(tokens).length === 0) return;

    const family = this.sdk.getProductFamily(this.sdk.targetProduct);

    switch (family) {
      case FAMILIES.UD:
        return this.getTokenForUD(appdataToken, tokens);
      case FAMILIES.CP:
        return this.getTokenForCP(appdataToken, tokens);
      case FAMILIES.AF:
        return this.getTokenForAF(appdataToken, tokens);
    }
  }

  getProductFromAppdataToken(appdataToken, tokens) {
    if (!appdataToken) return null;
    const product = Object.keys(tokens).find(
      (product) =>
        tokens[product].token === appdataToken && tokens[product].isLoggedIn
    );

    if (PRODUCTS_DISABLED_AS_SOURCE.includes(product)) {
      return null;
    }

    return product ? { token: appdataToken, product } : null;
  }

  getEligibleSourceProducts(tokens, filterToken = true) {
    return Object.keys(tokens).filter(
      (product) =>
        !PRODUCTS_DISABLED_AS_SOURCE.includes(product) &&
        tokens[product].isLoggedIn &&
        (filterToken ? tokens[product].token : true)
    );
  }

  getProductByFamily(family, products, tokens) {
    const product = products.find(
      (product) => this.sdk.getProductFamily(product) === family
    );
    return product ? { token: tokens[product].token, product } : null;
  }

  getTokenForUD(appdataToken, tokens) {
    let result = this.getProductFromAppdataToken(appdataToken, tokens);
    if (result) return result;

    const sourceProducts = this.getEligibleSourceProducts(tokens);
    result = this.getProductByFamily(FAMILIES.CP, sourceProducts, tokens);
    if (result) return result;

    return this.getProductByFamily(FAMILIES.AF, sourceProducts, tokens);
  }

  getTokenForAF(appdataToken, tokens) {
    let result = this.getProductFromAppdataToken(appdataToken, tokens);
    if (result) return result;

    const sourceProducts = this.getEligibleSourceProducts(tokens);
    result = this.getProductByFamily(FAMILIES.AF, sourceProducts, tokens);
    if (result) return result;

    result = this.getProductByFamily(FAMILIES.CP, sourceProducts, tokens);
    if (result) return result;

    return this.getProductByFamily(FAMILIES.UD, sourceProducts, tokens);
  }

  getTokenForCP(appdataToken, tokens) {
    if (appdataToken) return null;

    const sourceProducts = this.getEligibleSourceProducts(tokens, false);
    let result = this.getProductByFamily(FAMILIES.UD, sourceProducts, tokens);
    if (result) return result;

    return this.getProductByFamily(FAMILIES.AF, sourceProducts, tokens);
  }
}

module.exports = DefaultCoregistrationStrategy;
