const { PRODUCT_ENDPOINTS, PRODUCT_NAMES, XEROX_PRODUCT_TO_PRODUCT_MAP} = require("../consts");
const { CupidPubSub } = require("../utils/pubsub");

class ActivationPopupRenderer {
  constructor(sdk) {
    this.sdk = sdk;
  }

  render(invite) {
      throw new Error('render method must be implemented');
  }
}

class CupidActivationPopupRenderer extends ActivationPopupRenderer {

  constructor(sdk) {
    super(sdk);
    this.pubsub = new CupidPubSub();
  }

  render({ cpAccount, cupidAccount }) {
    const fragment = this.#createFragment({
      product: cpAccount.product,
      preferredGender: cupidAccount.searchCriteria.gender,
      unreadMessages: cpAccount.unreadLetters,
      token: cpAccount.token,
    });

    window._cupidmeida.modal.show(fragment)

    this.#trackEvents({ product: cpAccount.product })

    return fragment;
  }

  #createFragment({ product, preferredGender, unreadMessages, token }) {
    const xeroxHost = this.sdk.xeroxHost
    const productHost = PRODUCT_ENDPOINTS[product]

    const fragment = document.createDocumentFragment();
    const div = document.createElement('div');
    div.className = 'xerox-activation-popup';

    const unreadMessagesText = this.sdk.localization.getLocalizedString('activation-popup', 'unread-messages-text', { count: unreadMessages > 5 ? '5+' : unreadMessages, _count: unreadMessages })
    const attractsAttentionText = this.sdk.localization.getLocalizedString('activation-popup', 'attracts-attention-text', { productName: PRODUCT_NAMES[XEROX_PRODUCT_TO_PRODUCT_MAP.get(product) ?? product] })
    const openInboxButtonText = this.sdk.localization.getLocalizedString('activation-popup', 'open-inbox-button')
    const closeButtonText = this.sdk.localization.getLocalizedString('activation-popup', 'close-button')

    const genderIllustrationMapping = {
      'Male': 'in-male',
      'Female': 'in-female',
      'Any': 'in-both'
    }

    const popupIllustration = genderIllustrationMapping[preferredGender] ?? genderIllustrationMapping['Any']

    div.innerHTML = /*html*/`
        <div>
          <img class="xerox-activation-popup__logo" src="${xeroxHost}sdk/public/activation-popup/logo/${product}.svg" alt="${product}">
          <picture class="xerox-activation-popup__banner">
            <source srcset="${xeroxHost}sdk/public/activation-popup/${popupIllustration}-mobile.png" media="(max-width: 575px)">
            <img src="${xeroxHost}sdk/public/activation-popup/${popupIllustration}.png">
          </picture>
        </div>
        <div class="xerox-activation-popup__content">
          <div class="xerox-activation-popup__texts">
            <h2 class="xerox-activation-popup__title">${unreadMessagesText}</h2>
            <div class="xerox-activation-popup__divider"></div>
            <p class="xerox-activation-popup__text">${attractsAttentionText}</p>
          </div>
          <a id="activation-popup-${product}-cta" class="xerox-activation-popup__open-inbox-btn" href="${productHost}people/#inbox&token=${token}" target="_blank" aria-controls="modal">
            ${openInboxButtonText}
          </a>
          <a class="xerox-activation-popup__close-btn" aria-controls="modal">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="31.9977" height="32.0004" rx="15.9989" fill="#999999"/>
              <path d="M23.4592 11.1497L18.6112 15.9977L23.4592 20.8457C23.8045 21.1922 23.9982 21.6615 23.9977 22.1507C23.9973 22.6398 23.8028 23.1088 23.4569 23.4547C23.1111 23.8005 22.6421 23.995 22.1529 23.9955C21.6638 23.9959 21.1945 23.8022 20.848 23.4569L16 18.6089L11.152 23.4569C10.981 23.6292 10.7776 23.7658 10.5535 23.8591C10.3294 23.9524 10.0891 24.0004 9.8464 24.0004C9.60369 24.0004 9.36337 23.9524 9.1393 23.8591C8.91523 23.7658 8.71182 23.6292 8.5408 23.4569C8.19453 23.1107 8 22.641 8 22.1513C8 21.6616 8.19453 21.192 8.5408 20.8457L13.3888 15.9977L8.5408 11.1497C8.19552 10.8033 8.00184 10.3339 8.00226 9.8448C8.00268 9.35566 8.19718 8.88667 8.54306 8.5408C8.88893 8.19492 9.35792 8.00042 9.84706 8C10.3362 7.99958 10.8055 8.19326 11.152 8.53854L16 13.3865L20.848 8.54174C21.1945 8.19646 21.6638 8.00278 22.1529 8.0032C22.6421 8.00362 23.1111 8.19812 23.4569 8.544C23.8028 8.88987 23.9973 9.35886 23.9977 9.848C23.9982 10.3371 23.8045 10.8065 23.4592 11.1529V11.1497Z" fill="white"/>
            </svg>
            <span>${closeButtonText}</span>
          </a>
        </div>
    `;

    fragment.appendChild(div);

    this.#addCssVars({
      fragment: fragment,
      sourceProduct: this.sdk.targetOriginProduct,
    });

    return fragment;
  }

  preloadStyles() {
    const xeroxHost = this.sdk.xeroxHost
    document.head.insertAdjacentHTML('beforeend', `
      <link rel="stylesheet" href="${xeroxHost}sdk/public/colors.css">
      <link rel="stylesheet" href="${xeroxHost}sdk/activation-popup/main.css?v2">
    `);
  }

  #addCssVars({ fragment, sourceProduct }) {
    const style = document.createElement('style');
    style.innerHTML = `
      .xerox-activation-popup {
            --theme-primary: var(--${sourceProduct}--theme-primary);
        }
    `;
    fragment.appendChild(style);
  }

  #trackEvents({ product }) {
    this.pubsub.publish({
      goal: 'sdvcrosspromo_activation_popup',
      label: product,
      placement: 'center',
      category: 'modal',
      action: 'impression'
    });

    const link = document.getElementById(`activation-popup-${product}-cta`)

    if (link) {
      link.addEventListener('click', () => {
        this.pubsub.publish({
          goal: 'sdvcrosspromo_activation_popup',
          label: product,
          placement: 'center',
          category: 'modal',
          action: 'click'
        });
      })
    }
  }
}

module.exports = {
  CupidActivationPopupRenderer
}
