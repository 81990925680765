async function gtm(e) {
  const TIMEOUT = 2000;

  return !window.dataLayer || !window.dataLayer.push
    ? Promise.resolve()
    : new Promise((resolve) => {
        const timeout = setTimeout(resolve, TIMEOUT);

        function eventCallback() {
          const fired = [];
          return function (containerId) {
            fired.push(containerId);
            if (
              fired.some((id) => id?.startsWith("GTM-")) &&
              fired.some((id) => id?.startsWith("G-"))
            ) {
              clearTimeout(timeout);
              resolve();
            }
          };
        }

        const params = {
          ...e,
          eventCallback: eventCallback(),
          eventTimeout: TIMEOUT,
        };

        window.dataLayer.push(params);
      });
};

module.exports = { gtm }