/**
 * Функция создает c перезаписью путь в объекте и устанавливает значение
 * ['key1', 'key2'] => object.key1.key2
 * возвращает объект
 */

module.exports = function setByPath(path, obj, value) {
    let res = obj || {};

    if (Array.isArray(path) && path.length === 0) {
        res = value;
        return res;
    }

    (Array.isArray(path) ? path : []).reduce(function (prev, curr, index, arr) {
        if (index === arr.length - 1) {
            prev[curr] = value;
        } else {
            prev[curr] = typeof prev[curr] === 'object' ? prev[curr] : {};
        }

        return prev[curr];
    }, res);

    return res;
};
