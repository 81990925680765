const IntlMessageFormat = require('intl-messageformat').default

class Localization {
  constructor({ language, xeroxHost }) {
    this.language = language;
    this.xeroxHost = xeroxHost || "https://xerox.clickocean.io/";
    this.state = {};
  }

  async loadNamespace(namespace, language = this.language) {
    try {
      const translations = await this.getTranslations({ namespace, language: language ?? this.language });
      this.state[namespace] = translations;
      return translations;
    } catch (error) {
      if (language !== 'en') {
        return this.loadNamespace(namespace, 'en');
      } else {
        throw new Error(error);
      }
    }
  }

  async getTranslations({ namespace, language }) {
    const response = await fetch(`${this.xeroxHost}sdk/${namespace}/texts/${language}.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get ${language} translations for ${namespace}`
      );
    }

    const body = await response.json();
    return body;
  }

  getLocalizedString(namespace, key, data = {}) {
    const template = this.state[namespace] ? this.state[namespace][key] : '';
    if (!template) return '';

    const numericCount = data._count !== undefined ? data._count : data.count;
    const formatData = { ...data };
    if (data._count !== undefined) {
      formatData.count = numericCount;
    }

    try {
      const messageFormatter = new IntlMessageFormat(template, this.language);
      let result = messageFormatter.format(formatData);
      if (data._count !== undefined && data.count !== numericCount) {
        result = result.replace(String(numericCount), data.count);
      }
      return result;
    } catch (err) {
      console.error('Failed to format message:', err);
      return template;
    }
  }

}

module.exports = Localization;
