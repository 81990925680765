const SOURCE_PROBABILITIES = {
  'contact-list': 0.7,
  'search': 0.3
}

module.exports = class FreeMembersManager {
  static chooseFreeMemberId(freeMemberIds) {
    return freeMemberIds[Math.floor(Math.random() * freeMemberIds.length)];
  }

  static chooseFreeMembersSource() {
    const rand = Math.random();
    let cumulative = 0;

    for (const [source, probability] of Object.entries(SOURCE_PROBABILITIES)) {
      cumulative += probability;
      if (rand < cumulative) {
        return source;
      }
    }
  }
}
