function tryConvertProductToSDGNaming (product) {
  const productLowerCase = product?.toLowerCase()
  switch (productLowerCase) {
    case 'africa':
      return 'afd'
    case 'arabia':
      return 'ard'
    case 'china':
      return 'zen'
    case 'christian':
      return 'ycd'
    case 'euro':
      return 'ed'
    case 'eurodate':
      return 'dc'
    case 'slavic1':
      return 'hot'
    case 'flirt':
      return 'fw'
    case 'guys':
      return 'god'
    case 'pink':
      return 'gio'
    case 'latin':
      return 'aml'
    case 'mature':
      return 'dma'
    case 'travel':
      return 'ytm'
    case 'pina':
      return 'pd'
    case 'woman':
      return 'olv'
    case 'astro':
      return 'asl'
    case 'ai':
      return 'if'
    case 'locals':
      return 'ud'
    case 'asiandate':
      return 'asd'
    case 'anastasiadate':
      return 'ad'
    case 'russianbrides':
      return 'rb'
    case 'tubit':
      return 'tbt'
    case 'cupid-international':
      return 'intc'
    case 'roulette':
      return 'bam'
    default:
      return productLowerCase
  }
}

module.exports = {
  tryConvertProductToSDGNaming
}
