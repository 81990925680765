function toSsoReturnUri (uri) {
  const returnUrl = new URL(uri)

  const fragmentParams = new URLSearchParams(returnUrl.hash.slice(1))
  fragmentParams.delete('sso_tokens')
  returnUrl.hash = fragmentParams.toString()

  return returnUrl.toString()
}

const toActionUri = (uri, noRedirectTokens) => {
  const url = new URL('https://sso-terra.clickocean.io/')

  url.searchParams.set('redirect_uri', toSsoReturnUri(uri))

  if (noRedirectTokens) {
    url.searchParams.set('no_redirect_tokens', 'true')
  }

  return url.toString()
}

const setTokenToSso = (ssoToken, redirectUri) => {
  const form = document.createElement('form')
  form.method = 'post'
  form.action = toActionUri(redirectUri, true)

  const setToken = document.createElement('input')
  setToken.type = 'hidden'
  setToken.name = 'set_token'
  setToken.value = JSON.stringify(ssoToken)
  form.appendChild(setToken)

  document.body.appendChild(form)
  form.submit()
}

module.exports = function redirectToUri (uri, { product, token, ssoEnabled, xdlsTokensToSSO = {} } = {}) {

  if (ssoEnabled) {
    return setTokenToSso({
      ...xdlsTokensToSSO,
      [product]: { token, isLoggedIn: true }
    }, uri)
  }

  window.location.replace(uri)
  const redirectUri = new URL(uri)

  if (
    window.location.pathname === redirectUri.pathname &&
    window.location.host === redirectUri.host &&
    window.location.search === redirectUri.search
  ) {
    window.location.reload()
  }
}
