//@ts-nocheck
const getByPath = require('./path');
const setByPath = require('./set-by-path');
const removeByPath = require('./remove-by-path');
const mixin = require('./mixin');

const MESSAGE_NAMESPACE = 'cross-domain-product-storage-message';

export default class Xdls {

    constructor({ host }) {
        const that = this;

        this.url = `${host}app/xdls/${window.btoa(window.document.location.host)}`;
        this.originTrialUrl = `${host}app/xdls/origin-trial.js`
        this.origin = host.replace(/\/$/, '');
        this.id = +(Date.now() + '' + Math.floor((Math.random() * 9000) + 1000));
        this.isReady = false;
        this.iframe = null;
        this.queue = [];
        this.requestId = -1;
        this.state = {};

        this.receiveMessage = this.receiveMessage.bind(this);

        if (window.document.readyState === 'complete') {
            this.init();
        } else {
            document.addEventListener('readystatechange', event => {
                if (event.target.readyState === 'complete') {
                    that.init();
                }
            });
        }
    }

    init() {
        const addIFrame =  () => {
            var temp = document.createElement('div')
            temp.innerHTML = '<iframe id="' + this.id + '" src=' + this.url + ' style="display: none;"></iframe>'

            window.addEventListener('message', this.receiveMessage, false)
            document.body.appendChild(temp)
            this.iframe = document.getElementById(this.id)
        }

        const originTrialScript = document.createElement('script')
        originTrialScript.src = this.originTrialUrl
        originTrialScript.onload = addIFrame
        originTrialScript.onerror = addIFrame
        document.body.appendChild(originTrialScript)
    }

    prepareOptions(options = {}) {
        let result = options;

        result.storageType = options.storageType ? options.storageType : 'local';

        return result;
    }

    receiveMessage(event) {
        var data;

        if (event.origin !== this.origin) {
            return;
        }

        try {
            data = JSON.parse(event.data);
        } catch (err) {
            //not our message, can ignore
        }

        if (data && data.namespace === MESSAGE_NAMESPACE) {
            if (data.id === 'iframe-ready') {
                this.state = data.value;
                this.isReady = true;
                this.flush();
            } else if (data.id === 'actualize' && this.isReady) {
                const { key, value, storageType } = data;
                this.state[storageType][key] = value;
            }
        }
    }

    flush() {
        this.queue.forEach((func) => { func(); });
        this.queue = [];
    }

    postMessage(data) {
        this.iframe.contentWindow.postMessage(JSON.stringify(data), '*');
    }

    buildMessage(action, path, value, storageType) {
        this.requestId++;

        const data = {
            namespace: MESSAGE_NAMESPACE,
            id: this.requestId,
            action: action,
            path: path,
            value: value,
            storage: storageType,
        };

        this.postMessage(data);
    }

    setItem(options) {
        const { path, value, callback, storageType } = this.prepareOptions(options);

        if (typeof path === 'undefined' || typeof value === 'undefined') {
            return Promise.resolve(false);
        }

        if (!this.isReady) {
            return new Promise((resolve) =>
                this.queue.push(() =>
                    resolve(this.setItem(options))));
        }

        this.buildMessage('setbypath', path, value, storageType);

        if (Array.isArray(path)) {
            setByPath(path, this.state[storageType], value);
        } else {
            this.state[storageType][path] = value;
        }

        callback && callback(true);

        return Promise.resolve(true);
    }

    getItem(options) {
        const { path, callback, storageType } = this.prepareOptions(options);

        if (typeof path === 'undefined') {
            return Promise.resolve(undefined);
        }

        if (!this.isReady) {
            return new Promise((resolve) =>
                this.queue.push(() =>
                    resolve(this.getItem(options))));
        }

        const data = Array.isArray(path) ? getByPath(path, this.state[storageType]) : this.state[storageType][path];

        callback && callback(data);

        return Promise.resolve(data);
    }

    removeItem(options) {
        const { path, callback, storageType } = this.prepareOptions(options);

        if (typeof path === 'undefined' || (Array.isArray(path) && path.length === 0)) {
            return Promise.resolve(false);
        }

        if (!this.isReady) {
            return new Promise((resolve) =>
                this.queue.push(() =>
                    resolve(this.removeItem(options))));
        }

        this.buildMessage('removebypath', path, null, storageType);

        if (Array.isArray(path)) {
            removeByPath(path, this.state[storageType]);
        } else {
            delete this.state[storageType][path];
        }

        callback && callback(true);

        return Promise.resolve(true);
    }

    clear(options) {
        if (!this.isReady) {
            return new Promise((resolve) =>
                this.queue.push(() =>
                    resolve(this.clear(options))));
        }

        const { callback, storageType } = this.prepareOptions(options);

        this.buildMessage('clear', null, null, storageType);

        delete this.state[storageType];

        callback && callback(true);

        return Promise.resolve(true);
    }

    patch(options) {
        const { path, value, callback, storageType, transformProvider = mixin } = this.prepareOptions(options);

        if (typeof path === 'undefined' || typeof value === 'undefined') {
            return Promise.resolve(false);
        }

        if (typeof value !== 'object') {
            return this.setItem(options);
        }

        if (!this.isReady) {
            return new Promise((resolve) =>
                this.queue.push(() =>
                    resolve(this.patch(options))));
        }

        const oldValue = Array.isArray(path) ? getByPath(path, this.state[storageType]) : this.state[storageType][path];
        const newValue = transformProvider(typeof oldValue === 'object' ? oldValue : {}, value);

        this.buildMessage('setbypath', path, newValue, storageType);

        if (Array.isArray(path)) {
            setByPath(path, this.state[storageType], newValue);
        } else {
            this.state[storageType][path] = newValue;
        }

        callback && callback(true);

        return Promise.resolve(true);
    }

}
