const CardBannerDefaultStrategy = require('./CardBannerDefaultStrategy');
const CardBannerCoregistratedStrategy = require('./CardBannerCoregistratedStrategy');

class CardBannerStrategyFactory {
  static create(service) {
    if (!!service.crossToken) {
      return new CardBannerCoregistratedStrategy(service);
    } else {
      return new CardBannerDefaultStrategy(service);
    }
  }
}

module.exports = CardBannerStrategyFactory;