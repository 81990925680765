class PubSub {
  publish(event) {
    throw new Error("Method 'publish' must be implemented.");
  }
}

class CupidPubSub extends PubSub {
  constructor() {
    super();
    try {
      const config = window.config;
      if (config) {
        this.SessionID = config.SessionID;
        this.DeviceID = config.DeviceID ?? config['DeviceID '];
        this.memberUUID = config.memberUUID;
        this.siteID = (dataLayer ?? [])[0]?.siteID;
        this.pubsubToken = config.pubsubtoken;
        this.pubsubTopic = config.googlePubSubTopicPlainText;
      }
    } catch (error) {
      console.error("PubSub: Error during initialization", error);
    }
  }

  async publish({ goal, label, placement, action, category, formData = {} }) {
    try {
      const payload = this.#prepare(
        action,
        category,
        {
          goal,
          label,
          placement,
          ...formData
        }
      );

      if (!payload) {
        return;
      }

      await fetch(`${this.pubsubTopic}:publish`, {
        method: "POST",
        cors: "no-cors",
        headers: {
          Authorization: "Bearer " + this.pubsubToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        keepalive: true
      });
    } catch (error) {
      console.error("PubSub: Error publishing event", error);
    }
  }

  #prepare(action, category, label) {
    try {
      const formatedData = {
        SessionID: this.SessionID,
        DeviceID: this.DeviceID,
        MemberID: this.memberUUID,
        PageURL: window.location.href,
        Category: category,
        TimeStamp: new Date().toISOString(),
        Label: this.#stringifyWithSingleQuotes(label),
        SiteID: this.siteID,
      };

      const escapedJsonData = JSON.stringify(formatedData).replace(/"/g, '\\"');
      const base64EncodedData = btoa(escapedJsonData);

      return {
        messages: [
          {
            data: base64EncodedData,
            attributes: {
              Action: action,
            },
          },
        ],
      };
    } catch (error) {
      console.error("PubSub: Error preparing payload", error);
      return null;
    }
  }

  #stringifyWithSingleQuotes(obj) {
    const replacer = (key, value) => {
       if (value === null) {
        return 'null';
      } else if (typeof value === 'object') {
        return value
      } else {
          return String(value).replace(/'/g, '"');
      }
    };

    const jsonString = JSON.stringify(obj, replacer);

    const singleQuotedString = jsonString.replace(/"/g, "'");
    return singleQuotedString;
  }
}

module.exports = {
  CupidPubSub
}
