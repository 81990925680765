/**
 *  Функция перемешивания объектов
 */

module.exports = function () {
    var args = Array.prototype.slice.call(arguments),
        ret = {},
        item;

    if (args.length === 0) {
        return;
    }

    while (args.length) {
        item = args.shift() || {};

        for (var key in item) {
            ret[key] = item[key];
        }

    }

    return ret;
};
