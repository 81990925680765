class Hasher {
  static async hash(data) {
    try {
      const encoder = new TextEncoder();
      const dataBuffer = encoder.encode(data);
    
      return crypto.subtle.digest('SHA-256', dataBuffer)
        .then(hashBuffer1 => {
          return crypto.subtle.digest('SHA-256', hashBuffer1);
        })
        .then(hashBuffer2 => {
          const hashArray = Array.from(new Uint8Array(hashBuffer2));
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
          return hashHex;
        });
    } catch (error) {
      console.error('Hasher: Error hashing data', error);
      return null;
    }
  }
}

module.exports = Hasher;