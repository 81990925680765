const { PRODUCT_ENDPOINTS, IMAGES_API_ENDPOINTS } = require("../consts");
const { CupidPubSub } = require("../utils/pubsub");

class CrossInvitesRenderer {
  constructor(sdk) {
    this.sdk = sdk;
  }

  render(invite) {
      throw new Error('render method must be implemented');
  }
}

class CupidCrossInvitesRenderer extends CrossInvitesRenderer {

  constructor(sdk) {
    super(sdk);
    this.pubsub = new CupidPubSub();

    document.body.addEventListener('click', (e) => {
      const ctaBtnEl = e.target.closest('.cross-invites__reply-btn');
      const closeBtnEl = e.target.closest('.cross-invites__close-btn');
      const inviteEl = e.target.closest('[data-invite-id]');

      if (closeBtnEl && inviteEl) {
        inviteEl.remove();
      } else if (ctaBtnEl && inviteEl) {
        const { inviteId, product, senderId, recipientId, type } = inviteEl.dataset;

        this.pubsub.publish({
          goal: 'improvecoregistrations',
          label: 'cross_invite',
          placement: 'bottom',
          action: 'click',
          category: 'modal',
          formData: {
            inviteId: inviteId,
            sourceProduct: product,
            targetProduct: sdk.targetOriginProduct,
            attendeeId: senderId,
            userId: recipientId,
            inviteType: type,
            displayType: 'nonblocking',
          }
        });
      }
    });
  }

  render(invite) {
    const fragment = this.#createFragment(invite);
    this.#showNotification(fragment, 15000);

    this.pubsub.publish({
      goal: 'improvecoregistrations',
      label: 'cross_invite',
      placement: 'bottom',
      action: 'impression',
      category: 'modal',
      formData: {
        inviteId: invite.id,
        sourceProduct: invite.product,
        targetProduct: this.sdk.targetOriginProduct,
        attendeeId: invite.senderId,
        userId: invite.recipientId,
        inviteType: invite.type,
        displayType: 'nonblocking'
      }
    });

    return fragment;
  }

  preloadStyles() {
    const xeroxHost = this.sdk.xeroxHost
    document.head.insertAdjacentHTML('beforeend', `
      <link rel="stylesheet" href="${xeroxHost}sdk/public/colors.css">
      <link rel="stylesheet" href="${xeroxHost}sdk/cross-invites/main.css">
    `);
  }

  #createFragment(invite) {
    const imagesHost = IMAGES_API_ENDPOINTS[invite.product]
    const productHost = PRODUCT_ENDPOINTS[invite.product]

    const fragment = document.createDocumentFragment();
    const div = document.createElement('div');
    this.#setDatasetToElement(div, {
      inviteId: invite.id,
      product: invite.product,
      senderId: invite.senderId,
      recipientId: invite.recipientId,
      type: invite.type
    });

    const replyNowText = this.sdk.localization.getLocalizedString('cross-invites', 'reply-now')

    div.innerHTML = `
      <div class="cross-invites">
        <div class="cross-invites__thumbnail">
          <img src="${imagesHost}/users/${invite.userInfo.id}/photos/${invite.userInfo.thumbnail}.120x120.thumb-fd" alt="Profile Picture">
        </div>
        <div class="cross-invites__info">
          <div class="cross-invites__texts">
            <strong class="cross-invites__header">
              <span>${invite.userInfo.name}</span>
              <span>(${invite.userInfo.age})</span>
            </strong>
            <p title="${invite.message}">${invite.message}</p>
          </div>
          <a class="cross-invites__reply-btn" href="${productHost}universal/dialogs/${invite.senderId}?token=${invite.token}" target="_blank">
            <span class="cross-invites__reply-btn-text">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2271_1838)">
                  <path d="M18.5302 1.54182L0.734787 8.4315C-0.265327 8.81599 -0.24977 9.40939 0.765902 9.74943L5.24197 11.2452L6.98217 16.6702C7.18886 17.3547 7.77337 17.5014 8.27787 16.9947L10.6626 14.6078L10.6915 14.5766L15.0586 17.8792C15.9143 18.5238 16.79 18.1926 17.0055 17.1414L19.9525 2.75085C20.1681 1.69962 19.528 1.15511 18.5279 1.54182H18.5302ZM15.6409 5.39338L8.2312 12.3364C8.09092 12.4836 8.00204 12.6723 7.97784 12.8742L7.78226 15.1545C7.68225 16.288 7.50445 15.8946 7.28665 15.2123L5.86871 10.8496L15.152 4.90443C16.5877 4.011 16.5877 4.52661 15.6409 5.39338Z" fill="white"/>
                </g>
              </svg>
              ${replyNowText}
            </span>
            <span class="cross-invites__loading-bar"></span>
          </a>
        </div>
        <button class="cross-invites__close-btn">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4592 3.14974L10.6112 7.99774L15.4592 12.8457C15.8045 13.1922 15.9982 13.6615 15.9977 14.1507C15.9973 14.6398 15.8028 15.1088 15.4569 15.4547C15.1111 15.8005 14.6421 15.995 14.1529 15.9955C13.6638 15.9959 13.1945 15.8022 12.848 15.4569L8 10.6089L3.152 15.4569C2.98097 15.6292 2.77757 15.7658 2.55349 15.8591C2.32942 15.9524 2.08911 16.0004 1.8464 16.0004C1.60369 16.0004 1.36337 15.9524 1.1393 15.8591C0.915226 15.7658 0.71182 15.6292 0.540796 15.4569C0.19453 15.1107 0 14.641 0 14.1513C0 13.6616 0.19453 13.192 0.540796 12.8457L5.3888 7.99774L0.540796 3.14974C0.195521 2.80326 0.00183565 2.33394 0.00225951 1.8448C0.00268338 1.35566 0.197182 0.886673 0.543057 0.540798C0.888932 0.194923 1.35792 0.00042456 1.84706 6.94236e-07C2.3362 -0.000423172 2.80552 0.193262 3.152 0.538537L8 5.38654L12.848 0.541737C13.1945 0.196462 13.6638 0.00277683 14.1529 0.00320069C14.6421 0.00362456 15.1111 0.198123 15.4569 0.543998C15.8028 0.889873 15.9973 1.35886 15.9977 1.848C15.9982 2.33714 15.8045 2.80646 15.4592 3.15294V3.14974Z" fill="white"/>
          </svg>
        </button>
      </div>
    `;

    fragment.appendChild(div);

    this.#addCssVars({
      id: invite.id,
      fragment: fragment,
      targetProduct: this.sdk.targetOriginProduct,
      sourceProduct: invite.product
    });

    return fragment;
  }

  #setDatasetToElement(element, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        element.dataset[key] = data[key];
      }
    }
  }

  #addCssVars({ id, fragment, sourceProduct, targetProduct }) {
    const xeroxHost = this.sdk.xeroxHost;
    const style = document.createElement('style');
    style.innerHTML = `
      [data-invite-id="${id}"] {
            --theme-primary: var(--${targetProduct}--theme-primary);
            --header-circle: var(--${targetProduct}--header-circle);
            --product-icon: url("${xeroxHost}sdk/public/cp/cross-invites/logo/${sourceProduct}.svg");
        }
    `;
    fragment.appendChild(style);
  }

  #showNotification(fragment, milliseconds) {
    window._cupidmeida.notification.show(fragment, milliseconds);
  }
}

module.exports = {
  CupidCrossInvitesRenderer
}
