const CrossInvitesManager = require("./CrossInvitesManager");
const CrossInvitesProcessor = require("./CrossInvitesProcessor");
const Poller = require("../utils/poller");

module.exports = class CrossInvitesService {
  constructor(sdk, { crossInvitesAvailability, crossInvitesRenderer, config }) {
    this.sdk = sdk;
    this.crossInvitesAvailability = crossInvitesAvailability;
    this.crossInvitesRenderer = crossInvitesRenderer;

    this.crossInvitesManager = new CrossInvitesManager({
      maxInvites: config.maxInvites,
      inviteWindow: config.inviteWindow,
    });

    this.poller = new Poller({
      callback: (sync) => this.loadAndProcessInvites(sync),
      interval: config.pollingInterval,
    });

    this.crossInvitesProcessor = new CrossInvitesProcessor(this.sdk);
  }

  async start({ token }) {
    if (!this.crossInvitesAvailability.isAvailable()) {
      return;
    }

    await this.sdk.localization.loadNamespace('cross-invites')
    this.crossInvitesRenderer.preloadStyles();

    try {
      this.clientId = token;

      this.crossToken = await this.sdk.crossTokenManager.getCrossToken({
        sourceProduct: this.sdk.targetProduct,
        sourceToken: this.clientId,
      });

      if (!this.crossToken) {
        console.log("CrossInvitesService: unable to get cross token");
        return;
      }

      this.poller.start();
    } catch (error) {
      console.error("CrossInvitesService: error getting cross token:", error);
    }
  }

  async loadAndProcessInvites(sync) {
    if (!this.crossInvitesManager.canShowInvite()) return sync;

    const events = await this.sdk.api.getCrossInvites({
      clientId: this.clientId,
      crossToken: this.crossToken,
      sync,
    });

    for (const event of events) {
      if (!this.crossInvitesManager.canShowInvite()) {
        break;
      }

      sync = event.sync;

      const invite = await this.crossInvitesProcessor.process(event);

      if (invite) {
        this.crossInvitesRenderer.render(invite);
        this.crossInvitesManager.recordInvite();
      }
    }

    return sync;
  }
}
