import { FAMILIES, Family } from '/src/sdk/consts'
import CrossInvitesService from './CrossInvitesService'
import SDK from '/src/sdk/sdk'
import { CupidCrossInvitesRenderer } from './CrossInvitesRenderer'
import { CupidCrossInvitesAvailability } from './CrossInvitesAvailability'

export default class CrossInvitesServiceFactory {
  private readonly sdk: SDK

  constructor (sdk: SDK) {
    this.sdk = sdk
  }

  create (family?: Family): CrossInvitesService | null {
    switch (family) {
      case FAMILIES.CM:
        return new CrossInvitesService(this.sdk, {
          crossInvitesAvailability: new CupidCrossInvitesAvailability(this.sdk),
          crossInvitesRenderer: new CupidCrossInvitesRenderer(this.sdk),
          config: {
            pollingInterval: 15000,
            maxInvites: 2,
            inviteWindow: 15 * 60 * 1000
          }
        })
      default:
        return null
    }
  }
}
