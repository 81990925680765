const tracking = require('./utils/tracking')

class API {
  constructor({ xeroxHost } = { xeroxHost: "https://xerox.clickocean.io/" }) {
    this.xeroxHost = xeroxHost;
  }

  async getIdentity({ apiHost, token }) {
    const response = await fetch(`${apiHost}identity`, {
      headers: {
        Authorization: `Token token="${token}"`,
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get identity from ${apiHost} for token ${token}`
      );
    }

    return await response.json();
  }

  async getAccountsFromTokens({ tokens }) {
    const response = await fetch(`${this.xeroxHost}accounts/tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tokens }),
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get accounts from ${this.xeroxHost} for token ${tokens}`
      );
    }

    const body = await response.json();
    return body?.users;
  }

  async getAccountsFromEmailHash({ emailHash, crossToken, fields = []}) {
    const url = new URL(`${this.xeroxHost}accounts/${emailHash}`);

    if (fields.length > 0) {
      const sortedFields = fields.slice().sort();
      url.searchParams.set("fields", sortedFields.join(","));
    }

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${crossToken}`,
      }
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get account from ${this.xeroxHost} for emailHash ${emailHash}`
      );
    }

    const body = await response.json();
    return body?.accounts;
  }

  async forceCoregistration({
    id,
    token,
    product,
    targetProduct,
    affiliate,
    authRequired = true,
  }) {
    const response = await fetch(
      `${this.xeroxHost}${product}/${id}/transfer-agreements/v3/${targetProduct}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          ...(affiliate && { affiliate: 1 }),
          "authorization-required": authRequired,
          source: 'force',
          ...tracking.params()
        }),
      }
    );

    if (response.status !== 200 && response.status !== 204) {
      throw new Error(
        `Failed to get forced coregistration uri to target product ${targetProduct} from ${this.xeroxHost} for token ${token} and product ${product}`
      );
    }

    const body = await response.json();
    return body?.uri;
  }

  async getCrossToken({ sourceProduct, sourceToken }) {
    const response = await fetch(`${this.xeroxHost}crosstoken/${sourceProduct}/${sourceToken}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      const error = new Error(
        `Failed to get cross token from ${this.xeroxHost} for product ${sourceProduct} and token ${sourceToken}`
      );
      error.status = response.status;
      throw error;
    }

    const body = await response.json();
    return body?.token;
  }

  async getCrossTokenV2({ sourceProduct, sourceToken }) {
    const response = await fetch(`${this.xeroxHost}v2/crosstoken/${sourceProduct}/${sourceToken}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      const error = new Error(
          `Failed to get cross token from ${this.xeroxHost} for product ${sourceProduct} and token ${sourceToken}`
      );
      error.status = response.status;
      throw error;
    }

    const body = await response.json();
    return body?.token;
  }

  async getCrossInvites({ clientId, sync, crossToken }) {
    const response = await fetch(`${this.xeroxHost}invitations?client-id=${clientId}&sync=${sync}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${crossToken}`,
      },
    });

    if (response.status !== 200) {
      const error = new Error(
        `Failed to get cross invites from ${this.xeroxHost} for client id ${clientId} with sync ${sync}. Status: ${response?.status}`
      );
      error.status = response.status;
      throw error;
    }

    const body = await response.json();
    return body;
  }

  async getUserInfo ({ apiHost, userId }) {
    const response = await fetch(`${apiHost}users/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get user info from ${apiHost} for user id ${userId}`
      );
    }

    const body = await response.json();
    return body;
  }

  async getFreeMembersFromContactList ({ product, memberUUID, crossToken }) {
    const response = await fetch(`${this.xeroxHost}promotions/${product}/${memberUUID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${crossToken}`,
      },
    });

    if (response.status !== 200) {
      const error = new Error(
        `Failed to get free-members for user ${userId} from ${this.xeroxHost}. Status: ${response?.status}`
      );
      error.status = response.status;
      throw error;
    }

    const body = await response.json();
    return body?.accounts;
  }

  async getFreeMembersFromSearch ({ product, gender = 'mal', preferredGender = 'fem', minage = 18, maxage = 90 }) {
    const params = new URLSearchParams({ gender, preferredGender, minage, maxage });
    const response = await fetch(`${this.xeroxHost}promotions/${product}?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get free-members for product ${product} from ${this.xeroxHost}`
      );
    }

    const body = await response.json();
    return body?.accounts;
  }

  async getUserPreferences ({ apiHost, userId }) {
    const response = await fetch(`${apiHost}users/${userId}/preferences`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get user info from ${apiHost} for user id ${userId}`
      );
    }

    const body = await response.json();
    return body;
  }

  async getUserPhotos ({ apiHost, userId }) {
    const response = await fetch(`${apiHost}users/${userId}/photos`, {
      method: "GET",
      headers: {
        "Accept": "application/vnd.sdv.filetags+json",
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
          `Failed to get user photos from ${apiHost} for user id ${userId}`
      );
    }

    const body = await response.json();
    return body?.files;
  }

  async getCupidAccountDetails ({ apiHost, token }) {
    const response = await fetch(`${apiHost}registration/getAccountDetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get user info from ${apiHost} for user id ${userId}`
      );
    }

    const body = await response.json();
    return body;
  }
}

module.exports = API;
