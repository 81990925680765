const { IMAGES_API_ENDPOINTS, XEROX_PRODUCT_TO_PRODUCT_MAP } = require("../consts");
const countries = require("./texts/countries.json");
class CardBannerRenderer {
  constructor(sdk) {
    this.sdk = sdk;
  }

  render(freeMember) {
    throw new Error("render method must be implemented");
  }
}

class CupidCardBannerRenderer extends CardBannerRenderer {
  render(renderOptions) {
    const fragment = this.#createFragment(renderOptions)
    
    const placeholder = document.querySelector("[data-martec-placeholder]")
    placeholder && placeholder.replaceWith(fragment);

    this.#trackEvents(renderOptions);

    return fragment;
  }

  hidePlaceholder() {
    const placeholder = document.querySelector("[data-martec-placeholder]")
    placeholder && placeholder.remove();

    window?._cupidmeida?.reflow && window._cupidmeida.reflow(true);
  }

  #createFragment(renderOptions) {
    const fragment = document.createDocumentFragment();
    const renderConfig = this.#createRenderConfig(renderOptions);

    const isDesktop = document.documentElement.dataset.device === 'desktop';
    const element = isDesktop
      ? this.#createDesktopElement(renderConfig)
      : this.#createMobileElement(renderConfig)

    this.#addStyles({ fragment });
    fragment.appendChild(element);

    return fragment;
  }

  #createRenderConfig({ freeMember, cardLink, isTrialAvailable }) {
    const user = {
      id: freeMember.userInfo.id,
      name: freeMember.userInfo.name,
      age: freeMember.userInfo.age,
      city: freeMember.userInfo.city,
      country: countries[freeMember.userInfo.country] ?? freeMember.userInfo.country,
      thumbnail: freeMember.userThumbnail,
      preferences: {
        minage: freeMember.userPreferences.minage ?? 18,
        maxage: freeMember.userPreferences.maxage ?? 99,
      }
    }

    const hosts = {
      xerox: this.sdk.xeroxHost ?? 'https://xerox.clickocean.io/',
      images: IMAGES_API_ENDPOINTS[freeMember.userInfo.realm]
    }

    const texts = this.#prepareTexts({ freeMember });

    return {
      targetProduct: XEROX_PRODUCT_TO_PRODUCT_MAP.get(freeMember.userInfo.realm),
      isTrialAvailable,
      cardLink,
      texts,
      hosts,
      user
    }
  }

  #prepareTexts({ freeMember }) {
    const genderMapping = {
      'mal': 'male',
      'fem': 'female',
      'both': 'any'
    }
    const online = this.sdk.localization.getLocalizedString("client-card-banner", "online");
    const seeking = this.sdk.localization.getLocalizedString("client-card-banner", "seeking");
    const trialAvailable = this.sdk.localization.getLocalizedString("client-card-banner", "trial-available");
    const preferedGender = this.sdk.localization.getLocalizedString("client-card-banner", genderMapping[freeMember.userPreferences['preferred-gender']] ?? 'any');

    return {
      online,
      seeking,
      trialAvailable,
      preferedGender,
    }
  }

  #trackEvents({ freeMember, onClick, onShow }) {
    onShow()

    const link = document.getElementById(`xerox-card-${freeMember.id}`)

    if (link) {
      link.addEventListener('click', onClick)
    }
  }

  #createDesktopElement({ user, cardLink, isTrialAvailable, texts, hosts, targetProduct }) {
    const element = document.createElement("div");
    element.className = "card-s card xerox-card m1 flex flex-wrap overflow-hidden bg-white shadow relative";
    element.innerHTML = /*html*/`
      <a id="xerox-card-${user.id}" href="${cardLink}" class="xerox-card__link"></a>

      <a class="flex items-center col-12 justify-center std relative" href="#">
        <div
          class="profile-bg"
          style="
            background-image: url('${hosts.images}/users/${user.id}/photos/${user.thumbnail}.250x250.thumb-fd');
          "
        ></div>
        <div class="xerox-card__product">
          <img src="${hosts.xerox}sdk/public/cp/client-card-banner/${targetProduct}.svg" />
        </div>
        ${isTrialAvailable ? `<span class="xerox-card__trial-tag">${texts.trialAvailable}</span>` : ''}
        <svg class="border-box z2 standard-online online circle border-white tooltip" data-tooltip="ttOn">
          <use xlink:href="/assets/desktop/icons/icons.svg?v=20240602091830&amp;#icon-online"></use>
        </svg>
      </a>

      <div class="sdh col-12 relative flex flex-column">
        <div class="flex items-center mx1-5 mt1-5 relative">
          <span class="h3 color-dark-grey m0 truncate max-width-85">${user.name}</span>
          <div class="icon-membership">
            <svg class="ms1 fill-standard" data-tooltip="ttCn">
              <use xlink:href="/assets/desktop/icons/icons.svg?v=20240602091830&amp;#icon-profile"></use>
            </svg>
          </div>
        </div>
        <div class="mx1-5 h5 text-truncate-multi text-truncate-multi-2">
          ${user.age} <span>•</span> ${user.city}, ${user.country}
        </div>
        <div class="mx1-5 h5 mt1">
          ${texts.seeking}: ${texts.preferedGender} ${user.preferences.minage} - ${user.preferences.maxage}<br />
          <span class="color-medium-grey">${texts.online}</span>
        </div>
        <div class="flex flex-auto items-end justify-between mx1-5 mb1-5">
          <div class="flex items-center">
            <div class="pointer me3 relative fill-action-unhighlight">
              <svg class="icon-30 pointer-events-none">
                <use xlink:href="/assets/desktop/icons/icons.svg?v=20240602091830&amp;#icon-heart"></use>
              </svg>
            </div>
            <div class="relative pointer me3 fill-action-unhighlight relative">
              <svg class="icon-30 pointer-events-none">
                <use
                  xlink:href="/assets/desktop/icons/icons.svg?v=20240602091830&amp;#icon-messages"
                ></use>
              </svg>
            </div>
          </div>
          <div class="fill-action-unhighlight relative">
            <div class="pointer no-highlight flex items-center relative">
              <svg class="icon-30 pointer-events-none">
                <use xlink:href="/assets/desktop/icons/icons.svg?v=20240602091830&amp;#icon-camera"></use>
              </svg>
              <div class="ms1 h5">4</div>
            </div>
          </div>
        </div>
      </div>
    `;
    return element;
  }

  #createMobileElement({ user, cardLink, isTrialAvailable, targetProduct, texts, hosts }) {
    const element = document.createElement("article");

    element.className = "card relative xerox-card";
    element.dataset.card = user.id;
    element.innerHTML = /*html*/`
      <a id="xerox-card-${user.id}" href="${cardLink}" class="xerox-card__link"></a>

      <div class="square relative">
        <div class="col-12 absolute left-0 bottom-0 z1"></div>
        <div class="slides square relative">
          <div style="transform: translateX(0vw) translateZ(0px);">
            <div class="slide square-min overflow-hidden relative active" data-complete="true">
              <img
                class="square-min img-cover"
                style="background-image: url('${hosts.images}/users/${user.id}/photos/${user.thumbnail}.250x250.thumb-fd');"
              >
            </div>
          </div>
        </div>
        <div class="xerox-card__product">
          <img src="${hosts.xerox}sdk/public/cp/client-card-banner/${targetProduct}.svg" />
        </div>
        ${isTrialAvailable ? `<span class="xerox-card__trial-tag">${texts.trialAvailable}</span>` : ''}
      </div>

      <div class="absolute top-0 left-0 col-12">
          <div class="m1 flex justify-between items-center flex-baseline">
              <div class="flex flex-baseline"></div>
          </div>
      </div>

      <div class="m1 relative h5 z2">
        <div class="standard-online flex items-start">
          <div class="ms1">
            <svg class="border-box flex items-center justify-center online circle border-white">
              <use xlink:href="/assets/mobile/icons/icons.svg?v=20240602091830&amp;#icon-online"></use>
            </svg>
          </div>
        </div>
        <div class="card-header flex relative m1 mb2 z1">
          <div class="col-12 flex items-start mt1">
            <div class="col-10 me2">
              <div class="flex flex-wrap items-center mb1 col-12">
                <div class="h3 clamp bold break-word">${user.name}</div>
                <div class="h3">, ${user.age}</div>
                <svg class="icon small ms1 membership-standard">
                  <use xlink:href="/assets/mobile/icons/icons.svg?v=20240602091830&amp;#icon-profile"></use>
                </svg>
              </div>
              ${user.city}, ${user.country}
              <div class="block"><span class="bold">${texts.seeking}:</span> ${texts.preferedGender} ${user.preferences.minage} - ${user.preferences.maxage}</div>
            </div>
            <div class="m0 h6 flex items-start justify-end">${texts.online}</div>
          </div>
        </div>
        <div class="flex justify-between items-center col-8 mx-auto mb3">
            <div class="relative fill-action-unhighlight">
              <svg class="icon-34">
                <use xlink:href="/assets/mobile/icons/icons.svg?v=20240602091830&amp;#icon-heart"></use>
              </svg>
            </div>
            <div class="chat circle flex items-center justify-center">
              <svg class="icon-34 fill-action-unhighlight">
                <use xlink:href="/assets/mobile/icons/icons.svg?v=20240602091830&amp;#icon-messages"></use>
              </svg>
            </div>
            <div class="relative fill-action-unhighlight">
              <svg class="icon-34">
                <use xlink:href="/assets/mobile/icons/icons.svg?v=20240602091830&amp;#icon-star"></use>
              </svg>
            </div>
        </div>
      </div>
    `;
    return element;
  }

  #addStyles({ fragment }) {
    const style = document.createElement('style');
    style.innerHTML = /*css*/`
      .xerox-card .xerox-card__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
      }
      .xerox-card .xerox-card__product {
        position: absolute;
        max-width: 50%;
        top: 0;
        left: 0;
        padding: 4px 16px;
        text-align: center;
        border-radius: 0px 0px 8px 0px;
        background: #999999;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
      }
      .xerox-card .xerox-card__product img {
        height: 24px;
        display: block;
        width: auto;
        max-width: 100%;
        object-fit: contain;
      }
      .xerox-card .xerox-card__trial-tag {
        position: absolute;
        left: 16px;
        bottom: 10px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: #D5232F;
        color: #FFF;
        font-size: 16px;
        line-height: 1.25; 
      }
      html[data-device="desktop"] .xerox-card .xerox-card__trial-tag {
        font-size: 12px;
        left: 12px;
      }
    `
    fragment.appendChild(style);
  }
}

module.exports = {
  CupidCardBannerRenderer,
};
