const DEFAULT_MIN_DAYS = 3;
const DEFAULT_MAX_DAYS = 7;
const PERSISTENCE_KEY = 'xerox.activationPopup.cooldown';

class ActivationPopupManager {
  constructor({ minDays = DEFAULT_MIN_DAYS, maxDays = DEFAULT_MAX_DAYS } = {}) {
    this.minDays = minDays;
    this.maxDays = maxDays;
  }

  canShow(product) {
    const cooldown = this.#getCooldown(product);

    if (!cooldown) {
      return true;
    }

    const now = new Date();

    return now >= cooldown;
  }

  recordShow(product) {
    const cooldown = this.#calculateCooldown();
    const data = localStorage.getItem(PERSISTENCE_KEY);
    const record = data ? JSON.parse(data) : {};

    record[product] = cooldown.toISOString();

    localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(record));
  }

  #getCooldown(product) {
    const data = localStorage.getItem(PERSISTENCE_KEY);

    if (!data) return null;

    const record = JSON.parse(data);
    const cooldown = record[product];

    return cooldown ? new Date(cooldown) : null;
  }

  #calculateCooldown() {
    const cooldown = new Date();
    const daysUntilNextShow = this.#randomizeDays(this.minDays, this.maxDays);
    cooldown.setDate(cooldown.getDate() + daysUntilNextShow);
    return cooldown;
  }

  #randomizeDays(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
};

module.exports = {
  ActivationPopupManager,
  PERSISTENCE_KEY
}
