const CoregistrationStrategy = require('./CoregistrationStrategy')
const { FAMILIES } = require('../consts')
const { gtm } = require('../utils/analytics')

const tryConvertLanguageCode = (lang) => {
  switch (lang) {
    case 'zh-Hans':
      return 'zc'
    case 'zh-Hant':
    case 'zh-TW':
      return 'zt'
    case 'nb-NO':
      return 'no'
    default:
      return lang.split('-')[0]
  }
}

class PopupCoregistrationStrategy extends CoregistrationStrategy {
  async coregistrate (sdk, { onCoregistrationStatus }) {
    this.sdk = sdk
    this.setStatus = onCoregistrationStatus ?? (() => {}) // Statuses: initiated, unavailable, pending, accepted, rejected, failed

    this.setStatus('initiated')
    this.hideGoogleOneTap()

    const popupShown = await this.internalCoregistrate()

    if (popupShown) {
      this.setStatus('pending')
    } else {
      this.setStatus('unavailable')
      this.showGoogleOneTap()
    }
  }

  async internalCoregistrate() {
    try {
      const ssoResult = this.sdk.getTokensFromSSO() || {}
      const { tokens: ssoTokens = {} } = ssoResult

      const xdlsResult = await this.sdk.getTokensFromXdls()
      if (!xdlsResult) return
      const { tokens: xdlsTokens } = xdlsResult

      const tokens = { ...xdlsTokens, ...ssoTokens }

      const filteredTokens = this.filterTokens(tokens)
      if (!filteredTokens || filteredTokens.length === 0) return

      const accounts = await this.sdk.api.getAccountsFromTokens({
        tokens: filteredTokens,
      })

      const account = this.selectBestAccount(accounts)

      if (!account) return

      account.originProduct = Object.values(tokens).find(({ token }) => token === account.token)?.originProduct
      if (!account.originProduct) return

      this.showCoregistrationPopup({
        account,
        onConfirm: async (cb) => {
          const params = {
            id: account.id,
            token: account.token,
            product: account.product,
            authRequired: false,
            affiliate: false,
          }
          try {
            this.setStatus('accepted')
            await this.sdk.coregistrateAndRedirect(params, cb)
          } catch (e) {
            console.error(e)
            this.setStatus('failed')
          }
        },
        onClose: () => {
          this.setStatus('rejected')
          this.showGoogleOneTap()
        },
      })
      return true
    } catch (e) {
      console.error(e)
      this.setStatus('failed')
      this.showGoogleOneTap()
    }
  }

  hideGoogleOneTap () {
    const style = document.createElement('style')
    style.id = 'hide-google-one-tap'
    const css = `
      iframe[src^="https://accounts.google.com/gsi/iframe"],
      #credential_picker_container,
      #credential_picker_iframe {
        display: none;
        visability: hidden;
      }
    `
    style.appendChild(document.createTextNode(css))
    document.head.appendChild(style)
  }

  showGoogleOneTap () {
    const styleElement = document.getElementById('hide-google-one-tap')
    if (styleElement) {
      styleElement.parentNode.removeChild(styleElement)
    }
  }

  selectBestAccount (accounts) {
    accounts = accounts
      .filter(account => !account.products?.includes(this.sdk.targetProduct))
      .sort((a, b) => {
        if (a.payment && b.payment) return new Date(b.payment) - new Date(a.payment)
        if (a.payment) return -1
        if (b.payment) return 1

        return new Date(b.created) - new Date(a.created)
      })

    return accounts[0]
  }

  filterTokens (tokens) {
    const loggedInProducts = Object.keys(tokens).filter(
      (product) => tokens[product].isLoggedIn
    )

    const family = this.sdk.getProductFamily(this.sdk.targetProduct)

    switch (family) {
      case FAMILIES.CM:
        return this.filterTokensForCM(tokens, loggedInProducts)
    }
  }

  filterTokensForCM (tokens, loggedInProducts) {
    const productsToIgnore = ['christian', 'woman', 'astro']

    if (this.sdk.cupidToCupidEnabled) {

      const cupidToCupidResult = this.getFamilyTokens(tokens, loggedInProducts, FAMILIES.CM) ?? []
      const cupidToCupidFilteredResult = cupidToCupidResult.filter(({ product }) => !productsToIgnore.includes(product))

      if (cupidToCupidFilteredResult.length > 0) {
        return cupidToCupidFilteredResult
      }

    }

    for (const family of [FAMILIES.CP, FAMILIES.AF]) {
      const result = this.getFamilyTokens(tokens, loggedInProducts, family) ?? []
      const filteredResult = result.filter(({ product }) => !productsToIgnore.includes(product))

      if (filteredResult.length > 0) {
        return filteredResult
      }
    }
  }

  getFamilyTokens = (tokens, loggedInProducts, family) => {
    const filteredProducts = loggedInProducts.filter(
      (product) => this.sdk.getProductFamily(product) === family
    )
    if (filteredProducts.length > 0) {
      return filteredProducts.map((product) => ({
        product,
        token: tokens[product].token,
      }))
    }
  }

  showCoregistrationPopup ({ account, onConfirm, onClose }) {
    const xeroxHost = this.sdk.xeroxHost || `https://xerox.clickocean.io/`
    const iframe = document.createElement('iframe')
    iframe.src = `${xeroxHost}sdk/popup/popup.${tryConvertLanguageCode(document.documentElement.lang)}.html`
    iframe.width = '380'
    iframe.height = '0'
    iframe.frameBorder = '0'
    iframe.id = 'uiFrame'
    iframe.zIndex = '10000'

    const analyticsData = {
      popup: "forced_coreg",
      project: account.originProduct,
      project_partner: this.sdk.targetOriginProduct,
    }

    if (document.body.clientWidth > 600) {
      iframe.style = `
        position: fixed;
        z-index: 1;
        top: 85px;
        right: 32px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
        border-radius: 8px;
      `
    } else {
      iframe.style = `
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        padding: 4px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
        border-radius: 8px;
      `
    }

    iframe.onload = () => {
      iframe.contentWindow.postMessage(
        {
          type: 'init',
          profileName: account.name,
          profileAge: account.age,
          profileThumbnail: account.thumbnail,
          targetProduct: this.sdk.targetOriginProduct,
          targetProductFamily: this.sdk.getProductFamily(this.sdk.targetProduct),
          product: account.originProduct,
          productFamily: this.sdk.getProductFamily(account.product),
        },
        '*'
      )

      window.addEventListener('message', (event) => {
        if (event.data.type && event.data.type === 'syncIframeHeight') {
          iframe.style.height = event.data.height + 'px'
        } else if (event.data.type && event.data.type === 'confirmed') {
          onConfirm(() => {
            gtm({ ...analyticsData, event: 'popup_account_created' })
          })
        } else if (event.data.type && event.data.type === 'closed') {
          document.body.removeChild(iframe);
          onClose()
        }

        // Analitycs
        if (event.data.type && event.data.type === 'shown') {
          gtm({ ...analyticsData, event: 'popup_shown' })
        } else if (event.data.type && event.data.type === 'open') {
          gtm({ ...analyticsData, event: 'popup_open' })
        } else if (event.data.type && event.data.type === 'confirmed') {
          gtm({ ...analyticsData, event: 'popup_confirm' })
        } else if (event.data.type && event.data.type === 'closed') {
          gtm({ ...analyticsData, event: 'popup_close' })
        }
      })
    }

    document.body.appendChild(iframe)
  }
}

module.exports = PopupCoregistrationStrategy
