const FreeMembersManager = require("./FreeMembersManager");
const { tryConvertProductToSDGNaming } = require("../utils/products");
const Hasher = require("../utils/hasher");
const { PRODUCT_ENDPOINTS } = require("../consts");
const CardBannerStrategy= require("./CardBannerStrategy");

class CardBannerCoregistratedStrategy extends CardBannerStrategy {

  async isUserEligible() {
    try {
      await this.service.stock.getFreeMembersFromContactList(this.service.targetProduct);
      return true
    } catch (error) {
      if (error.status === 410) {
        console.log('CardBannerService: Not eligible user');
        return false;
      }
      return true;
    }
  }

  async getFreeMember() {
    const source = FreeMembersManager.chooseFreeMembersSource();

    if (source === 'contact-list' && this.service.stock.isEmtpy()) {
      await this.service.stock.getFreeMembersFromContactList(this.service.targetProduct);
    }

    if (source === 'search' || this.service.stock.isEmtpy()) {
      await this.service.stock.getFreeMembersFromSearch(this.service.targetProduct);
    }

    const freeMemberIds = this.service.stock.freeMembers.map(({ id }) => id);

    return await this.getFreeMemberFromList(freeMemberIds)
  }

  async checkTrialAvailability() {
    const emailHash = await Hasher.hash(this.service.cupidAccount.email)
    const sdvAccounts = await this.service.sdk.api.getAccountsFromEmailHash({ emailHash, crossToken: this.service.crossToken });

    const targetAccount = sdvAccounts.find(account => account.product === this.service.targetProduct);

    if (!targetAccount) {
      return false;
    }

    return targetAccount.trial === 1 && targetAccount['trial-activated'] === 0;
  }

  generateCardLink({ freeMember, isTrialAvailable }) {
    const productEndpoint = PRODUCT_ENDPOINTS[freeMember.userInfo.realm]
    const url = new URL(`${productEndpoint}people/`);
    url.hash = freeMember.userInfo.id;
    
    if (isTrialAvailable) {
      const hashParams = new URLSearchParams();
      const referent = tryConvertProductToSDGNaming(this.service.sourceProduct)

      hashParams.set('referent', referent);
      hashParams.set('referral-trial-purchase', '1');
      hashParams.set('cupid-banner', '1');

      url.hash += `&${hashParams.toString()}`;
    }

    return url.toString()
  }
}

module.exports = CardBannerCoregistratedStrategy;