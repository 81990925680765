class Poller {
  constructor({ callback, interval }) {
    this.interval = interval;
    this.pollingTimer = null;
    this.sync = null;
    this.callback = callback;
    this.errorsCount = 0;
  }

  start() {
    this.pollingTimer = setInterval(async () => {
      try {
        this.sync = await this.callback(this.sync ?? 0);
        this.errorsCount = 0;
      } catch (error) {
        this.handleError(error);
      }
    }, this.interval);
  }

  stop() {
    if (this.pollingTimer) {
      clearInterval(this.pollingTimer);
      this.pollingTimer = null;
    }
  }

  handleError(error) {
    if (error.status === 410) {
      this.stop();
    } else {
      this.errorsCount++;

      if (this.errorsCount > 3) {
        this.stop();
      }
    }
  }
}

module.exports = Poller;
