const { API_ENDPOINTS, IMAGES_API_ENDPOINTS } = require("../consts");
const preloadImage = require("../utils/preload-image");

const SUPPORTED_INVITE_TYPES = ['invitation'];

module.exports = class CrossInvitesProcessor {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async process(event) {
    try {
      const invite = this.#getInviteFromEvent(event);

      if (!SUPPORTED_INVITE_TYPES.includes(invite.type)) {
        console.log(`Unsupported invite type: ${invite.type}`);
        return;
      }

      const apiHost = this.sdk.apiHosts[invite.product] || API_ENDPOINTS[invite.product]

      if (!apiHost) {
        throw new Error(`Product ${invite.product} is not supported`)
      }
      
      const userInfo = await this.sdk.api.getUserInfo({ apiHost, userId: invite.senderId });
      userInfo.age = new Date().getFullYear() - new Date(userInfo.birthday).getFullYear();

      await preloadImage(`${IMAGES_API_ENDPOINTS[invite.product]}/users/${userInfo.id}/photos/${userInfo.thumbnail}.120x120.thumb-fd`);

      return {
        ...invite,
        userInfo,
      };
    } catch (error) {
      throw error;
    }
  }

  #getInviteFromEvent(event) {
    switch (event.type) {
      case 'message:message:v2':
        return {
          id: event.details.id,
          type: event.details.type,
          senderId: event.details.sender,
          recipientId: event.details.recipient,
          message: event.details.text,
          product: event.__product,
          token: event.__token,
        };
      case 'added:event':
        return {
          id: event.details.payload.id,
          type: event.details.type,
          senderId: event.details.payload['sender-id'],
          recipientId: event.details.payload['recipient-id'],
          message: event.details.payload.text,
          product: event.__product,
          token: event.__token,
        };
      default:
        throw new Error(`Unknown invite event type: ${event.type}`);
    }
  }
}