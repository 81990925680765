const CryptoJS = require('crypto-js');

function getABtestGroup(id, experiment, control_share = 50) {
    const input = String(id) + String(experiment);
    const hashHex = CryptoJS.MD5(input).toString();
    const hashBigInt = BigInt("0x" + hashHex);
    const remainder = hashBigInt % BigInt(100);
    return remainder < BigInt(control_share) ? 'control' : 'test';
}

module.exports = { getABtestGroup }
