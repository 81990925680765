const CardBannerService = require('./CardBannerService');
const { CupidCardBannerRenderer } = require("./CardBannerRenderer");
const { CupidCardBannerAvailability } = require("./CardBannerAvailability");
const { CupidPubSub } = require("../utils/pubsub");
const { FAMILIES } = require('../consts')

class CardBannerServiceFactory {
  constructor(sdk) {
    this.sdk = sdk
  }

  create(family) {
    switch (family) {
      case FAMILIES.CM:
        return new CardBannerService(this.sdk, {
          cardBannerAvailability: new CupidCardBannerAvailability(this.sdk),
          cardBannerRenderer: new CupidCardBannerRenderer(this.sdk),
          pubsub: new CupidPubSub()
        })
      default:
        return null
    }
  }
}

module.exports = CardBannerServiceFactory;
